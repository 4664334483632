import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"
import { useContentResolver } from "@app/hooks/useContentResolver"

type Props = {
  content: any
  contentRef?: any
} & BoxProps

const ResolvedContent: React.FC<Props> = ({ content, contentRef, ...props }) => {
  const { resolvedContent } = useContentResolver(content)

  return content ? (
    <Box ref={contentRef} {...props}>
      {resolvedContent}
    </Box>
  ) : null
}

export default React.memo(ResolvedContent)
