export const Heading = {
  baseStyle: {
    color: "inherit",
    fontFamily: "Larsseit",
    fontFeatureSettings: '"ss01" on, "ss02" on',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1,
    _hover: {
      textDecoration: "none",
    },
    _activeLink: {
      textDecoration: "none",
    },
  },
  variants: {
    h1: {
      fontSize: [40, null, null, 50],
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: [30, null, null, 32],
      lineHeight: [1.3, null, null, 1.4],
    },
    h3: {
      fontSize: [20, null, null, 26],
      lineHeight: [1.3, null, null, "34px"],
    },

    heading14: {
      fontSize: 14,
      lineHeight: "18px",
    },
    heading16: {
      fontSize: 16,
      lineHeight: "24px",
    },
    heading18: {
      fontSize: 18,
      lineHeight: "26px",
    },
    heading20: {
      fontSize: 20,
      lineHeight: "28px",
    },
    heading22: {
      fontSize: 22,
      lineHeight: "28px",
    },
    heading24: {
      fontSize: 24,
      lineHeight: 1.4,
    },
    heading26: {
      fontSize: 26,
    },
    heading30: {
      fontSize: 30,
      lineHeight: "42px",
    },

    mono: {
      fontFamily: "Courier",
    },
    // Used in sectionTiles which is not used on the site ATM
    headerTile: {
      color: "inherit",
      fontSize: ["32px", null, null, "48px"],
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: ["40px", null, null, "60px"],
    },
    footerTitle: {
      fontFamily: "Larsseit",
      fontSize: "16px",
      lineHeight: "24px",
    },
    footerTitleAlt: {
      fontFamily: "Larsseit",
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16px",
      textTransform: "uppercase",
      marginBottom: "5px",
    },
  },
}
