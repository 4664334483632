import { useMemo } from "react"

export const useShopifyImage = (url: string, size: string) => {
  const scaledUrl = useMemo(() => {
    if (!url?.includes("cdn.shopify.com")) {
      return url
    }

    return url.replace(/^(.+?)(.(?:png|jpg|webp).+)$/, `$1_${size}$2`)
  }, [size, url])

  return { scaledUrl }
}
