import React, { useMemo } from "react"
import { Box, Button, Image, Text, Table, Td, Th, Tr, Heading } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import Icon from "@app/components/Icon"

export const useContentResolver = (content: any) => {
  const {
    helpers: { sanityContent },
  } = useCore()
  const { anyUrlResolver } = useRoutes()

  const formatContent = (content: any) => {
    return sanityContent(content, {
      marks: {
        link: ({ children, mark }: { children: any; mark: any }) => {
          const alignmentMark = children?.[0]?.props?.node?.markKey

          let linkJsx
          const to = anyUrlResolver(mark?.link)
          const isLink = mark?.link?._type === "link"
          const isButton = mark?.style?.toLowerCase()?.endsWith("button")
          const isIconButton = isButton && mark?.style !== "button"
          const isExternal = mark?.link?.type === "external"

          if (to) {
            const buttonJsx = isButton ? (
              <Button
                variant={isIconButton ? "fillIcon" : isExternal ? "fillSmall" : "fill"}
                minW={mark?.link?.type === "external" ? "100px" : "160px"}
                mt={{ base: "5", sm: isIconButton || isExternal ? "5" : "10" }}
              >
                {mark?.style?.startsWith("mail") ? (
                  <Icon name="mail" width="24px" height="17px" mr="2.5" pos="relative" fill="none" />
                ) : mark?.style?.startsWith("phone") ? (
                  <Icon name="phone" width="18px" height="18px" mr="2.5" pos="relative" fill="none" />
                ) : null}
                <span>{children}</span>
              </Button>
            ) : (
              <span>{children}</span>
            )

            linkJsx = (
              <Link to={to?.url} textDecoration="none" external={to?.external} _hover={{ textDecoration: "none" }}>
                {buttonJsx}
              </Link>
            )
          } else {
            linkJsx = <span>{children}</span>
          }

          return (
            <Box
              as="span"
              {...(alignmentMark
                ? { textAlign: alignmentMark === "alignRight" ? "right" : alignmentMark === "alignCenter" ? "center" : "left" }
                : {})}
              display={isLink ? "inline" : "block"}
            >
              {linkJsx}
            </Box>
          )
        },
        alignLeft: ({ children }: { children: any }) => (
          <Box as="span" display="block" textAlign="left">
            {children}
          </Box>
        ),
        alignCenter: ({ children }: { children: any }) => (
          <Box as="span" display="block" textAlign="center">
            {children}
          </Box>
        ),
        alignRight: ({ children }: { children: any }) => (
          <Box as="span" display="block" textAlign="right">
            {children}
          </Box>
        ),
        color: ({ children, mark }) => (
          <Box as="span" display="block" style={{ color: mark?.hex }}>
            {children}
          </Box>
        ),
        linkMessage: ({ children, mark }: { children: any; mark: any }) => {
          const to = anyUrlResolver(mark?.link)
          if (to) {
            return (
              <Link to={to?.url} textDecoration="underline">
                {children}
              </Link>
            )
          } else {
            return <span>{children}</span>
          }
        },
      },
      types: {
        block: (props: any) => {
          switch (props.node.style) {
            case "h1":
              return (
                <Heading
                  as="h1"
                  variant="h1"
                  marginTop="5"
                  marginBottom="5"
                  fontSize={{ base: "64px", sm: "80px" }}
                  fontWeight="700"
                  lineHeight={{ base: "80px", sm: "84px" }}
                >
                  {props.children}
                </Heading>
              )
            case "h2":
              return (
                <Heading
                  as="h2"
                  variant="h2"
                  marginTop="5"
                  marginBottom="5"
                  fontSize={{ base: "48px", sm: "64px" }}
                  fontWeight="700"
                  lineHeight={{ base: "60px", sm: "80px" }}
                >
                  {props.children}
                </Heading>
              )
            case "h3":
              return (
                <Heading
                  as="h3"
                  variant="h3"
                  marginTop="5"
                  marginBottom="5"
                  fontSize={{ base: "32px", sm: "48px" }}
                  fontWeight="700"
                  lineHeight={{ base: "40px", sm: "60px" }}
                >
                  {props.children}
                </Heading>
              )
            case "h4":
              return (
                <Heading
                  as="h4"
                  variant="h4"
                  fontSize={{ base: "24px", sm: "32px" }}
                  fontWeight="700"
                  lineHeight={{ base: "30px", sm: "40px" }}
                >
                  {props.children}
                </Heading>
              )
            case "h5":
              return <h5>{props.children}</h5>
            case "h6":
              return <h6>{props.children}</h6>
            case "large":
              return <p className="h3 content-p">{props.children}</p>
            case "small":
              return <p className="small">{props.children}</p>
            case "subtext":
              return <p className="subtext">{props.children}</p>
            case "text":
              return <Text variant="text16">{props.children}</Text>
            default:
              return <p className="content-p">{props.children}</p>
          }
        },
        contentTable: ({ node }: { node: any }) => {
          const { headerColumns = [], rows = [], layout } = node

          return (
            <Box overflowX="auto">
              <Table
                my="30px"
                sx={{
                  "table-layout": layout || "auto",
                  "th, td": {
                    letterSpacing: 0,
                    borderColor: "rgba(0,0,0,.15)",
                  },
                }}
              >
                {!!headerColumns.length && (
                  <Tr>
                    {headerColumns.map((column: any, headerColIndex: number) => (
                      <Th
                        key={headerColIndex}
                        fontFamily="Larsseit"
                        fontWeight={400}
                        fontSize={16}
                        lineHeight="16px"
                        textAlign="left"
                        px={0}
                        pt={0}
                        pb="5"
                        textTransform="none"
                        color="brand.black"
                      >
                        {column}
                      </Th>
                    ))}
                  </Tr>
                )}
                {!!rows.length &&
                  rows.map((row: any, rowIndex: number) => (
                    <Tr key={rowIndex}>
                      {!!row.columns.length &&
                        row.columns.map((column: any, colIndex: number) => (
                          <Td
                            key={colIndex}
                            py="5"
                            px={0}
                            sx={{
                              "font-variant-numeric": "normal",
                              p: {
                                marginTop: "3.5",
                                marginBottom: "3.5",
                              },
                            }}
                          >
                            {formatContent(column?.content)}
                          </Td>
                        ))}
                    </Tr>
                  ))}
              </Table>
            </Box>
          )
        },
        contentSvg: ({ node }: { node: any }) => <Box mb={{ base: "5", sm: "10" }} dangerouslySetInnerHTML={{ __html: node?.svg }} />,
        customImage: ({ node }: { node: any }) => <Image src={node?.asset?.url} alt={node?.alt} />,
      },
      list: (props: any) => (
        <div className="content-list">{props.type === "bullet" ? <ul>{props.children}</ul> : <ol>{props.children}</ol>}</div>
      ),
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resolvedContent = useMemo(() => formatContent(content), [content])

  return { resolvedContent }
}
