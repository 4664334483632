export const Tabs = {
  baseStyle: {
    tablist: {
      gap: "16px",
      justifyContent: "start",
      mb: "24px",
    },
    tab: {
      color: "primary.navy",
      d: "inline-block",
      fontSize: "20px",
      lineHeight: 1.16,
      px: "40px",
      py: "20px",
      borderRadius: "32px",
      backgroundColor: "primary.white",
      _hover: {
        backgroundColor: "primary.purple",
      },
      _selected: {
        backgroundColor: "primary.purple",
      },
      _active: {
        backgroundColor: "primary.purple",
      },
    },
    tabpanel: {
      backgroundColor: "primary.white",
      p: 0,
    },
  },
  defaultProps: {
    size: "unset",
    variant: "unset",
  },
}
