import React, { useEffect, useState } from "react"
import { Box, BoxProps } from "@chakra-ui/react"

type Props = {
  url: string
  loadTime: number
  handleLoad?: () => void
} & BoxProps

const TvLoading: React.FC<Props> = ({ url, loadTime, handleLoad, ...props }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
      handleLoad?.()
    }, loadTime)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      pos="absolute"
      bgColor="brand.darkBlue"
      w="100%"
      h="100%"
      top={0}
      left={0}
      transition="all .1s ease-in-out"
      opacity={loading ? 1 : 0}
      visibility={loading ? "visible" : "hidden"}
      userSelect={loading ? "auto" : "none"}
      pointerEvents={loading ? "auto" : "none"}
      {...props}
      sx={{ video: { objectFit: { base: "contain", sm: "cover" }, pos: "absolute", w: "100%", h: "100%", top: 0, left: 0 } }}
    >
      <video autoPlay={true} playsInline={true} muted={true} loop={true}>
        <source src={url} />
      </video>
    </Box>
  )
}

export default React.memo(TvLoading)
