export type Wishlist = {
  anonRead: boolean
  cnt: number
  cts: number
  di: string
  id: string
  itemcmnts: number
  lhash: string
  lid: string
  listcontents: WishlistItem[]
  lname: string
  lty: string
  pid: string
  st: number
  userinfo: WishlistUserInfo
  uts: number
  _pkey: string
  _t: number
  isDefault?: boolean
  shared?: boolean
}

export type WishlistItem = {
  cts: number
  di: string
  dt: string
  du: string
  empi: number
  epi: number
  id: string
  iu: string
  lid: string
  pid: string
  pr: number
  uts: number
  _pkey: string
  _t: number
}

type WishlistUserInfo = {
  acc_mkt: boolean
  cts: number
  em: string
  extags: string[]
  fname: string
  lname: string
  last_platform_order_id: string | null
  m: any | null
  orders_count: number
  pid: string
  platform_mkt_consent: {
    sms: boolean | null
    email: boolean | null
  }
  prefs: {
    Mediums: {
      [key: string]: {
        acceptmarketing: boolean
        acceptsource: string
        signuptime: number
      }
    }
  }
  state: string
  sw_mkt: any | null
  total_spent: string
  uid: string
  ut: number | null
  uts: number
  _pkey: string
  _t: number
}

export enum WishlistShareColumns {
  CUSTOMER_NAME = "Customer name",
  CUSTOMER_EMAIL = "Customer email",
  CUSTOMER_ID = "Customer ID",
  RECIPIENT_EMAIL = "Recipient email",
  SHARE_MESSAGE = "Share message",
  SHARE_MEDIUM = "Share medium",
  WISHLIST_NAME = "Wishlist name",
  WISHLIST_ID = "Wishlist ID",
  SHARED_DATE = "Shared date",
  PRODUCT_NAMES = "Product names",
  PRODUCT_HANDLES = "Product handles",
  PRODUCT_IDS = "Product IDs",
  PRODUCT_CODES = "Product codes",
}
