import React, { useCallback } from "react"

import { Box, BoxProps, IconButton } from "@chakra-ui/react"
import Icon from "@app/components/Icon"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"

const SearchWidget: React.FC<BoxProps> = ({ ...props }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { state, dispatch } = useAppContext()

  const onClick = useCallback(async () => {
    if (typeof window === "undefined") {
      return
    }

    if (window.location.pathname.includes(routes.SEARCH)) {
      const searchField = document.getElementById("results-page-form")
      searchField?.focus()
      window.scrollTo({ top: 0, behavior: "smooth" })
      return
    }

    dispatch({
      type: "search",
      payload: !state.activeSearch,
    })

    const placeholder = document.createElement("input")
    placeholder.setAttribute("type", "text")
    placeholder.style.position = "absolute"
    placeholder.style.opacity = 0
    placeholder.style.height = 0
    placeholder.style.fontSize = "16px"
    document.body.prepend(placeholder)
    placeholder.focus()

    setTimeout(() => {
      document.getElementById("search-field")?.focus()
      placeholder.remove()
    }, 500)
  }, [dispatch, routes.SEARCH, state.activeSearch])

  return (
    <Box onClick={onClick}>
      <IconButton
        variant="headerIcon"
        aria-label="Open search"
        icon={<Icon name="search" width="48px" height="48px" p="14px" />}
        cursor="pointer"
        {...props}
      />
    </Box>
  )
}

export default React.memo(SearchWidget)
