import { space } from "./spacing"

export const sizes = {
  ...space,
  160: "40rem",
  "9xl": "100rem",
  "10xl": "110rem",
  "11xl": "120rem",
  container: {
    fluid: "9999px",
    desktop: "1440px",
    xs: "600px",
    sm: "800px",
    md: "1000px",
    lg: "1200px",
    xl: "calc(100vw - 48px)",
  },
}
