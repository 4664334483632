import { useMemo } from "react"
import { useWorlds } from "@app/hooks/useWorlds"

export const useProductWorld = (product: any) => {
  const { getProductWorld } = useWorlds()

  const world = useMemo(() => getProductWorld(product), [getProductWorld, product])

  return { world }
}
