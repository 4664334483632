// NOTE: Please add all new colors under the "primary" or "preset" key in the colors object
// "primary" is for colors that are used in the primary theme of the site
// "preset" is for colors that are configurable within Sanity

// TODO: Move all colors to the away from "brand" and into "primary" or "preset" whenever possible.
export const colors = {
  brand: {
    white: "#ffffff",
    offWhite: "#fbfbfb",
    black: "#000000",
    grayBorder: "#bfbfbf",
    grayDisabled: "#A4A5AA",
    grayText: "rgba(28,31,42,.3)",
    grayBg: "#f7f7f5",
    grayImage: "#F8F8F8",
    grayAlt: "#A4A5AA",
    gold: "#ffae61",
    orange: "#ffae61",
    green: "#a5e065",
    teal: "#2bd2c9",
    red: "#f9423a",
    yellow: "#eaac3e",
    blue: "#77a5ff",
    darkBlue: "#1c1f2a",
    offBlue: "#bdd6e6",
    bubble: "#afe2e3",
    greenClassic: "#a5e065",
    offYellow: "#F8E49A",
    bondiBeach: "#1CD9CF",
    greenGentle: "#72cdb2",
    oilBust: "#ffae61",
    orangeBust: "#ffae61",
    happyCurls: "#2bd2c9",
    tealCurly: "#2bd2c9",
    classicCare: "#5bace3",
    blueClassic: "#5bace3",
    purple: "#7b95da",
    addressBorder: "#808080",
    fruityFloss: "#3A64F9",
    chocolateBrown: "#473623",
    palepink: "#FED9D8",
  },
  primary: {
    navy: "#1C1F2A",
    white: "#FFFFFF",
    black: "#000000",
    greyLight: "#FAFAFA",
    greyDisabled: "#A4A5AA",
    greyDark: "#77797F",
    darkNavy: "#14161D",
    overlay: "rgba(67,66,62,0.6)",
    lime: "#D4FF73",
    cream: "#FFE2CC",
    purple: "#C9A8FF",
    purpleLight: "#E9E2FF",
    blue: "#93C2F9",
    pink: "#FFBCE2",
    lightPink: "#FFE1F2",
  },
  preset: {
    default: "#1C1F2A",
    white: "#FFFFFF",
    black: "#000000",
    worldRed: "#F9423A",
    worldRedAccent: "#FEECEB",
    worldOrange: "#FFAE61",
    worldOrangeAccent: "#FFF7EF",
    worldGreen: "#A5E065",
    worldGreenAccent: "#F6FCF0",
    worldTeal: "#2BD2C9",
    worldTealAccent: "#EAFBFA",
    worldBlue: "#77A5FF",
    worldBlueAccent: "#E4EDFF",
    worldLightBlue: "#BDD6E6",
    worldLightBlueAccent: "#F1F7FC",
    rainbow: "linear-gradient(113deg, #F9423A 1.28%, #FFAE61 21.02%, #A5E065 40.77%, #2BD2C9 60.51%, #77A5FF 80.26%, #BDD6E6 100%)",
    rainbowSecondary: "linear-gradient(113deg, #F9423A, #FFAE61 20%, #A5E065 40%, #2BD2C9 60%, #77A5FF 80%, #BDD6E6 100%)",
  },
}
