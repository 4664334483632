import React from "react"
import Icon from "@app/components/Icon"
import { BoxProps } from "@chakra-ui/react"

type Props = {
  color?: string
  filled: boolean
} & BoxProps

const WishlistIcon: React.FC<Props> = ({ color, filled, ...props }) => (
  <Icon
    name="heart"
    width="48px"
    height="48px"
    p="14px"
    color={filled ? "brand.red" : color || "brand.darkBlue"}
    fill={filled ? "brand.red" : "transparent"}
    _hover={{ fill: "brand.red", color: "brand.red" }}
    {...props}
  />
)

export default React.memo(WishlistIcon)
