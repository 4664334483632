module.exports = {
  id: "allkindsus",
  title: "Allkinds",
  description:
    "Say hello to awesome: all kinds of bath, body, haircare, skincare & fun stuff made just for kids. And with zero gross stuff.",
  url: "allkinds.com/us",
  subfolder: process.env.GATSBY_ACTIVE_ENV === "production" ? "/us" : "",

  locationCurrencyCode: "USD",
  locationRegion: "US",
  locationRegionFullName: "American",
  locationCountries: "US",
  locationForceRegion: true,
  locationLookupUrl: "https://ipapi.co/json/?key=Di68RWc6asPYru3MCgBD5bAZIMWaQwIFirSSKxUNjtwx9xDVnU",

  shopifyPlus: false,
  shopifyApiVersion: "2024-07",
  shopifyShopName: "allkinds-us",
  shopifyShopDomain: "allkinds-us.myshopify.com",
  shopifyShopDomainOther: "allkinds-prd.myshopify.com",
  shopifyCheckoutUrl: "checkout-us.allkinds.com",
  shopifyStorefrontToken: "e42df8824d2a8a12f4520a2ecf1cd115",
  shopifyStorefrontTokenOther: "5ccb0d9202557d8a1b9a577ad32e7035",
  shopifyMetafieldIdStoreSync: "25008900145289",
  minimumOrderValue: 40,

  googleSheetsSpreadsheetId: "1VhEb12HgZJ9bOY2GIZ1Xaa9ebj6z4WuZWOXPVIlvvds",
  googleSheetsSheetId: "US",

  sanityProjectId: "3oef59p4",
  sanityDataset: "production-us",
  sanityApiVersion: "2022-02-24",
  sanityToken:
    "skBoBI9fgEuAQxyYIWmwR1UaOXiCCkSLD69ce0lIIQazPjGb81Kb87Am9yIo8thtIFJjcLMY6IPwkU3DqlpPg98JkQ75CbhNfrKTvMRZi3dVbaKhAK2g6IkIxu5uzOlsaycOiBKBQIb39QN7yttUYYA12yGL31xhdYQ9OVNwRr2vjfA41fMJ",

  emarsysAnalyticsId: "14546C130B134F17",
  emarsysSkinQuizFormID: "2781",
  emarsysAgeRangeFormID: "28674",
  emarsysNewsletterFormID: "2372",
  emarsysCartFieldId: "31908",
  emarsysSignupUrl: "https://link.allkinds.com/u/register.php?CID=816904316&f=2418&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y",
  emarsysCompetitionUrl:
    "https://link.allkinds.com/u/register.php?CID=816904316&f=2822&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y&interest[]=[Interessen]",
  emarsysNewsletterUrl:
    "https://suite40-cdnnet.emarsys.net/u/register.php?CID=816904316&f=2372&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=[Opt-in]&interest[]=[Interessen]",

  mapboxAccessToken: "pk.eyJ1IjoiYnJhbmRiYW5rIiwiYSI6ImNsOTNkYnVsOTAxZmozdnIyNDN0ZjhzanEifQ.rRjLiL_nVNUjPcGxkSfHKA",
  flowProxyEndpoint: "https://api.brandbank.com.au",
  giftCardType: "Gift Card",
  stockBrand: "",
  stockServiceUrl: "https://api.brandbank.com.au/inventory/v2/findinstore",
  givexBrand: "allkinds-us",
  givexEndpoint: "https://givex-giftcards.azurewebsites.net",

  wishlistPlusStorefrontEndpoint: "https://swymstore-v3pro-01.swymrelay.com/api/",
  wishlistPlusAdminEndpoint: "https://swymstore-v3pro-01.swymrelay.com/storeadmin/v3/user/",
  wishlistPlusApiKey: "CNH1yZdqXmPdmFU6cenwuJt53Lq0abSh_-I8BFS5Yhk9N4CHahJtdBbC3xI3nSYgKAxaiURo6SAOQYLI-yx9Sw",
  wishlistPlusPid: "9l/j4Ggnu1jJ1VuVpFEFwSCBemzNgbB4buy8vv6NbOg=",
  yotpoAppKey: "hjoPjGUv2l7ADXcFDo2eLpogxzhJpaRw3nlKKAsL",
}
