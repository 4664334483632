import { useCallback, useEffect, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCartContext } from "@app/providers/cart"
import { useCore } from "./useCore"
import { useCart } from "./useCart"
import { useShopify } from "./useShopify"
import { useConfigContext } from "@app/providers/config"

export const useGiftWithPurchase = (autoAdd = false) => {
  const { settings } = useConfigContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { cart, count } = useCartContext()
  const { removeFromCart, addToCart, loading } = useCart()
  const { formatMoney } = useShopify()
  const { gwpSettings } = useStaticQuery<GatsbyTypes.StaticGiftWithPurchaseQuery>(graphql`
    query StaticGiftWithPurchase {
      gwpSettings: sanitySettingGwp {
        threshold
        includeDiscounts
        messageBefore
        messageAfter
        giftWithPurchaseEnabled
        giftWithPurchaseExclude
        giftWithPurchaseProducts {
          autoAdd
          product {
            title
            shopify {
              id
              handle
              raw
            }
          }
        }
      }
    }
  `)

  const { gwpAutoAdd, gwpAttribute } = settings.cart
  const lines = useMemo(() => cart?.lines || [], [cart?.lines])

  const isGwp = useCallback(
    (item: any): boolean => !!item?.attributes?.some((ca: { key: string; value: string }) => ca.key === gwpAttribute),
    [gwpAttribute]
  )

  const totalCartCost = useMemo((): number => {
    const filteredLineItems = lines?.filter((item: any) => {
      const isGwPItem = isGwp(item)
      const excluded = gwpSettings?.giftWithPurchaseExclude
        ? item?.merchandise?.product?.tags?.includes(gwpSettings?.giftWithPurchaseExclude)
        : false
      return !isGwPItem && !excluded
    })

    const appliedDiscounts = filteredLineItems?.map(item => item?.discountAllocations?.[0]?.discountedAmount.amount).filter(Boolean) ?? 0
    const totalAppliedDiscount = appliedDiscounts ? appliedDiscounts.reduce((total, amount) => total + parseFloat(amount), 0) : 0

    const subtotal =
      filteredLineItems?.reduce((acc: number, item: any) => {
        return acc + item?.merchandise?.priceV2?.amount * item?.quantity
      }, 0) ?? 0

    return subtotal - totalAppliedDiscount
  }, [lines, isGwp, gwpSettings])

  const { threshold, percentage, message } = useMemo(() => {
    const threshold = gwpSettings?.threshold ?? 100
    const total = totalCartCost
    const percentage = total > threshold ? 100 : (total / threshold) * 100
    const remaining = total > threshold ? 0 : threshold - total
    const rawMessage = percentage === 100 ? gwpSettings?.messageAfter : gwpSettings?.messageBefore
    const message = rawMessage?.replace("{threshold}", formatMoney(threshold))?.replace("{remaining}", formatMoney(remaining))
    return { threshold, percentage, message }
  }, [gwpSettings, totalCartCost, formatMoney])

  const minimumSpend = useMemo(() => threshold || 0, [threshold])

  const existsInCart = useCallback(
    (variantId: string) => {
      const lineItemIds = lines.filter(line => isGwp(line)).map(item => decodeShopifyId(item.merchandise.id, "ProductVariant"))
      return lineItemIds.includes(decodeShopifyId(variantId, "ProductVariant"))
    },
    [decodeShopifyId, isGwp, lines]
  )

  const addGwp = useCallback(
    async (product, variant, autoAdd = false) => {
      const attributes = [
        {
          key: gwpAttribute,
          value: "true",
        },
      ]

      if (autoAdd)
        attributes.push({
          key: gwpAutoAdd,
          value: "true",
        })

      await addToCart(product, variant, 1, attributes)
    },
    [addToCart, gwpAutoAdd, gwpAttribute]
  )

  const removeGwp = useCallback(
    async (product: any) => {
      const id = product?.id
      const variantId = product?.variant?.id
      await removeFromCart(id, variantId)
    },
    [removeFromCart]
  )

  /**
   * Create array of products from parsed sanity product Shopify raw data.
   * Product needs to have at least one available variant and not already be in the cart.
   */
  const giftWithPurchaseProductsAvailable = useMemo(() => {
    return gwpSettings?.giftWithPurchaseProducts
      ?.map(({ product, autoAdd }) => (product?.shopify?.raw ? { ...(JSON.parse(product.shopify.raw) || {}), autoAdd } : null))
      .filter((p: any | null) => p?.variants.find((v: any) => v.availableForSale === true)) as any[]
  }, [gwpSettings?.giftWithPurchaseProducts])

  const giftWithPurchaseProducts = useMemo(() => {
    return giftWithPurchaseProductsAvailable.filter((p: any | null) => {
      const availableVariant = p?.variants.find((v: any) => v.availableForSale === true)
      if (!availableVariant) return

      return p && !existsInCart(availableVariant.id)
    }) as any[]
  }, [giftWithPurchaseProductsAvailable, existsInCart])

  const isAGwpInCart = useMemo(() => {
    if (!lines?.length) return false

    return lines?.some((item: any) => isGwp(item))
  }, [lines, isGwp])

  const giftWithPurchaseItems = useMemo(() => {
    return giftWithPurchaseProducts.length > 0
  }, [giftWithPurchaseProducts.length])

  const giftWithPurchaseEnabledSanity = useMemo(() => {
    return gwpSettings?.giftWithPurchaseEnabled
  }, [gwpSettings?.giftWithPurchaseEnabled])

  const giftWithPurchaseEnabled = useMemo(() => {
    const hasGwpPurchase = gwpSettings?.giftWithPurchaseEnabled && giftWithPurchaseItems

    return !!(hasGwpPurchase && totalCartCost >= minimumSpend && !isAGwpInCart)
  }, [gwpSettings?.giftWithPurchaseEnabled, minimumSpend, totalCartCost, isAGwpInCart, giftWithPurchaseItems])

  const notEligibleForGwp = useMemo(() => {
    return !gwpSettings?.giftWithPurchaseEnabled || totalCartCost < minimumSpend
  }, [gwpSettings?.giftWithPurchaseEnabled, minimumSpend, totalCartCost])

  const autoAddHandler = useCallback(async () => {
    const gwpCartItem = lines.find((item: any) => isGwp(item))

    if (giftWithPurchaseEnabled && !gwpCartItem) {
      const gwp = giftWithPurchaseProducts?.find(p => !!p.autoAdd)
      const availableVariant = gwp?.variants.find((v: any) => v.availableForSale === true)
      if (availableVariant) setTimeout(async () => await addGwp(gwp, availableVariant, true), 1500)
    }
  }, [lines, giftWithPurchaseEnabled, isGwp, giftWithPurchaseProducts, addGwp])

  // add autoAdd gwp products
  // intentionally only run on cart count update
  useEffect(() => {
    if (!loading && autoAdd) autoAddHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  // auto remove GWP from the cart if the cart total dips below threshold
  // intentionally only run on cart count update
  useEffect(() => {
    const findGwp = lines.find((item: any) => isGwp(item))
    if (!loading && findGwp && notEligibleForGwp && autoAdd) setTimeout(() => removeGwp(findGwp), 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return {
    gwpSettings,
    giftWithPurchaseEnabled,
    giftWithPurchaseProducts,
    isAGwpInCart,
    totalCartCost,
    giftWithPurchaseEnabledSanity,
    giftWithPurchaseProductsAvailable,
    addGwp,
    percentage,
    message,
  }
}
