import { useCallback, useEffect, useState } from "react"

export const useActiveTab = () => {
  const [visibilityState, setVisibilityState] = useState(false)

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === "visible")
  }, [])

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [handleVisibilityChange])

  return { visibilityState }
}
