import { useMemo } from "react"

export const useColorPreset = (colorPreset: any) => {
  const color = useMemo(() => {
    if (!colorPreset) {
      return null
    }

    switch (colorPreset?.preset) {
      case "bubble":
        return "linear-gradient(270deg, #f8e49a 9.17%, #afe2e3 49.99%, #d8c4e5 91.67%)"
      case "offYellow":
        return "linear-gradient(272.79deg, #F8E49A 0%, #CA9E84 99.47%)"
      case "bondiBeach":
        return "linear-gradient(88.84deg, #1CD9CF 19.88%, #AAEBA4 67.36%, #D5F39F 99.01%)"
      case "custom":
        return colorPreset?.custom?.hex
      case "customCss":
        return colorPreset?.customCss
      default:
        return `brand.${colorPreset?.preset || colorPreset}`
    }
  }, [colorPreset])

  return {
    color,
  }
}
