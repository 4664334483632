import React, { useCallback, useMemo, useState, useEffect } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AspectRatio,
  Box,
  Button,
  Flex,
  Text,
  Image,
  Radio,
  RadioGroup,
} from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { useLiveProducts } from "@app/hooks/useLiveProducts"
import { useCart } from "@app/hooks/useCart"
import { useColorPreset } from "@app/hooks/useColorPreset"
import Icon from "@app/components/Icon"
import ResolvedContent from "@app/components/ResolvedContent"
import { NormalisedProduct } from "@root/types/product"
import { useShopifyPrice } from "@app/hooks/useShopify"

type Props = {
  listRef?: React.Ref<HTMLDivElement>
}

export const CartGiftWrap: React.FC<Props> = React.memo(({ listRef }) => {
  const { count, giftWrapActive } = useCartContext()
  const {
    settings: { cart },
  } = useConfigContext()
  const { addToCart, loading: cartLoading, giftWrapping } = useCart()

  const rawProducts = useMemo(
    () =>
      giftWrapping?.product?.map(product => ({
        ...product?._rawProduct,
        productTitle: product?.productTitle,
      })) || [],
    [giftWrapping?.product]
  )

  const { liveProducts } = useLiveProducts(rawProducts)
  const giftWrapProducts = liveProducts.map((liveProduct: NormalisedProduct) => {
    const staticProduct = rawProducts.find(({ shopify }) => shopify.handle === liveProduct.handle)
    return {
      ...liveProduct,
      productTitle: staticProduct?.productTitle,
    }
  })

  const { color } = useColorPreset(giftWrapping?.color)
  const { color: bodyColor } = useColorPreset(giftWrapping?.bodyColor)
  const resolvedTextColor = `brand.${giftWrapping?.textColor === "light" ? "white" : "black"}`

  const [selectedProduct, setselectedProduct] = useState("")

  useEffect(() => {
    if (giftWrapProducts.length > 0 && !selectedProduct) {
      setselectedProduct(giftWrapProducts[0]?.productTitle)
    }
  }, [giftWrapProducts, selectedProduct])

  const selectedProductObj = useMemo(
    () => giftWrapProducts.find(product => product?.productTitle === selectedProduct),
    [selectedProduct, giftWrapProducts]
  )

  const { formattedPrice } = useShopifyPrice(selectedProductObj?.variants?.[0], 1)

  const handleAddToCart = useCallback(async () => {
    if (selectedProduct && !cartLoading) {
      const selectedProductObj = giftWrapProducts.find(product => product?.productTitle === selectedProduct)
      await addToCart(selectedProductObj, selectedProductObj?.variants[0], 1, [
        {
          key: cart.giftWrapAttribute,
          value: "true",
        },
      ])
      if (listRef?.current) listRef.current.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [listRef, addToCart, cartLoading, giftWrapProducts, cart.giftWrapAttribute, selectedProduct])

  return giftWrapping && count > 0 && !giftWrapActive ? (
    <Accordion allowToggle>
      <AccordionItem border="unset">
        {({ isExpanded }) => (
          <>
            <AccordionButton bg={color} color={resolvedTextColor} _hover={{ bgColor: color }} py={3.5} px={5}>
              <Flex alignItems="center" justifyContent="space-between" w="full">
                <Text variant="text14">{giftWrapping.title}</Text>
                {giftWrapping.icon && <Box w="30px" ml={1} dangerouslySetInnerHTML={{ __html: giftWrapping.icon }} />}
              </Flex>
              <Box>
                <Icon name={isExpanded ? "minus" : "plus"} width="12px" height="12px" />
              </Box>
            </AccordionButton>
            <AccordionPanel p={5} bg={bodyColor}>
              <Flex alignItems="flex-start" justifyContent="space-between" flexDirection={{ base: "column", sm: "row" }} mb="16px">
                {giftWrapping.content && (
                  <ResolvedContent
                    content={giftWrapping.content}
                    sx={{
                      ".content-p": {
                        fontSize: { base: "10px", sm: "xs" },
                        m: 0,
                        mb: 2,
                      },
                    }}
                  />
                )}
              </Flex>

              <RadioGroup onChange={value => setselectedProduct(value)} value={selectedProduct}>
                <Flex alignItems="flex-start" justifyContent="space-between" gap={{ base: "4px", sm: "16px" }} mb={2} flexWrap="wrap">
                  {giftWrapProducts.map((product, index) => (
                    <Flex
                      key={product.id}
                      background={{ base: "transparent", sm: "brand.white" }}
                      alignItems="center"
                      flex={{
                        base: "100%",
                        sm: giftWrapProducts.length % 2 !== 0 && index === 0 ? "100%" : "45%",
                      }}
                      gap="16px"
                    >
                      <Box maxWidth={{ base: "56px", sm: "60px" }} width="full" mr="12px">
                        {product?.images[0] && (
                          <AspectRatio
                            flexShrink={0}
                            ratio={68 / 85}
                            w="68px"
                            borderRadius={{ base: "4px", sm: "0px" }}
                            overflow={{ base: "hidden", sm: "none" }}
                          >
                            <Image src={product.images[0].src} pointerEvents="none" w="100%" />
                          </AspectRatio>
                        )}
                      </Box>

                      <Radio value={product?.productTitle} colorScheme="customColor" verticalAlign="middle" ml="12px">
                        <Text fontSize={{ base: "12px", md: "12px" }} fontWeight={500}>
                          {product.productTitle}
                        </Text>
                      </Radio>
                    </Flex>
                  ))}
                </Flex>
              </RadioGroup>

              <Flex flexShrink={0} pt={{ base: 2, sm: "16px" }} justifyContent="flex-end">
                <Button onClick={handleAddToCart} px={4} variant="fillSmall" isLoading={cartLoading} isDisabled={!selectedProduct}>
                  Add {formattedPrice}
                </Button>
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  ) : null
})
