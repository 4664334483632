import { useStaticQuery, graphql } from "gatsby"
import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"

import type { Location } from "@root/types/global"

type BreadcrumbProps = {
  data: any
}

export const useBreadcrumb = (location?: Location) => {
  const {
    helpers: { capitalise, isBrowser },
  } = useCore()
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const breadcrumb = urlResolver({ title: "Home" }, "/home")

  const { global, organisation } = useStaticQuery<GatsbyTypes.StaticBreadcrumbsQuery>(graphql`
    query StaticBreadcrumbs {
      global: sanityTemplateGlobal {
        title
      }
      organisation: sanitySettingOrganisation {
        title
        separator
      }
    }
  `)

  const cleanTitle = (title: string) =>
    title
      ?.replace(global?.title || "", "")
      ?.replace(organisation?.title || "", "")
      ?.replace(` ${organisation?.separator} `, "")
      ?.replace(organisation?.separator || "", "")

  const breadcrumbState = (state: any) =>
    location?.pathname.includes(`${routes.COLLECTION}/`) && isBrowser
      ? {
          ...state,
          breadcrumbs: [
            { title: "Collections", url: routes.COLLECTION },
            {
              title: cleanTitle(window.document.title),
              url: location.pathname,
            },
          ],
        }
      : state

  const parentResolver = (parentCollections: Array<string>, item: any) => {
    if (item?.parentCollection?.shopify?.handle) {
      parentCollections.unshift(item.parentCollection)
      parentResolver(parentCollections, item.parentCollection)
    }
  }

  const buildBreadcrumbs = ({ data }: BreadcrumbProps): Array<BreadcrumbProps> => {
    const items: Array<any> = [breadcrumb]
    const item = data?.page || data?.product || data?.collection || data?.article || data?.store
    const currentUrl = urlResolver(item)?.url
    const paths = currentUrl?.split("/")?.slice(1)

    if (location?.pathname.includes(routes.PRODUCT)) {
      if (location.state?.breadcrumbs) {
        items.push(...location.state.breadcrumbs)
      } else {
        items.push({ title: "Collections", url: routes.COLLECTION })
      }
    }

    if (item) {
      if (data?.collection) {
        const parentCollections = [item]
        parentResolver(parentCollections, item)
        items.push({ title: "Collections", url: routes.COLLECTION })
        parentCollections.map(collection => {
          urlResolver(collection)
          items.push({
            ...item,
            title: collection?.title?.trim(),
            url: urlResolver(collection).url,
          })
        })
      } else {
        paths?.map(path => {
          if (path === item?.handle?.current || path === item?.shopify?.handle) {
            items.push({
              ...item,
              title: item?.title?.trim(),
              url: urlResolver(item)?.url,
            })
          } else {
            if (path !== "products")
              items.push({
                ...item,
                title: `${capitalise(path?.trim())}`,
                url: `/${path}`,
              })
          }
        })
      }
    }

    return items
  }

  return {
    breadcrumb,
    buildBreadcrumbs,
    breadcrumbState,
  }
}
