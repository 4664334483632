import { useMemo } from "react"
import { useImage } from "@app/hooks/useImage"

export const useImageResolver = (image: any, maxImageWidth?: number) => {
  const { getGatsbyImage } = useImage()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resolvedImage = useMemo(() => getGatsbyImage(image, maxImageWidth ? { width: maxImageWidth } : null), [image, maxImageWidth])

  return { resolvedImage }
}
