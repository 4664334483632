import React, { useCallback } from "react"
import { Flex } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import Icon from "@app/components/Icon"

const NavigationHeaderWidget: React.FC = () => {
  const { state, dispatch } = useAppContext()

  const onToggle = useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state.activeMenu])

  return (
    <Flex w={12} h={12} justifyContent="flex-start" cursor="pointer" onClick={onToggle}>
      <Icon name="hamburger" w={5} />
    </Flex>
  )
}

export default React.memo(NavigationHeaderWidget)
