import React, { useMemo } from "react"
import { Box, Heading, Button, Flex } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, EffectFade } from "swiper"
import "swiper/css/navigation"
import "swiper/css/effect-fade"

import { useCartContext } from "@app/providers/cart"
import { useCartUpsell } from "@app/hooks/useCartUpsell"
import Icon from "@app/components/Icon"
import { CartUpsellProductCard } from "@app/components/Cart/CartUpsellProductCard"
import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

interface CartProgressProps {
  cartMiniProgress?: number
}

export const CartUpsell: React.FC<CartProgressProps> = React.memo(() => {
  const { products, title } = useCartUpsell()
  const { cart } = useCartContext()

  const upsellCompleted = useMemo(() => {
    if (!products || products.length === 0) return false
    return products.every(product => cart?.lines.some((lineItem: CartLine) => lineItem?.merchandise.id === product?.variants?.[0]?.id))
  }, [products, cart?.lines])

  const filteredLiveProducts = useMemo(() => {
    if (!products) return []
    return products
      .filter(product => !cart?.lines.some((lineItem: CartLine) => lineItem?.merchandise.id === product?.variants?.[0]?.id))
      ?.filter(product => product?.variants?.[0]?.availableForSale)
  }, [products, cart?.lines])

  if (upsellCompleted || !filteredLiveProducts?.length) return null

  return (
    <Box>
      <Box
        width="120%"
        height="6px"
        opacity=".5"
        marginLeft="-20px"
        background="linear-gradient(90deg,#ffae61,#eaac3e,#a5e065,#2bd2c9,#77a5ff,#f9423a)"
        my={{ base: "14px", md: "28px" }}
      />
      <Flex direction="row" justifyContent="space-between" mb="14px">
        <Heading fontSize="18px" as="h5" fontWeight={700}>
          {title}
        </Heading>
        <Flex
          justifyContent="space-between"
          width="100px"
          position="relative"
          alignItems="center"
          sx={{
            ".swiper-button-next::after, .swiper-button-prev::after": {
              content: '""',
            },
          }}
        >
          <Button className="swiper-button-prev" top="unset" marginTop="unset">
            <Icon name="carouselChevronLeft" h="14px" w="14px" />
          </Button>

          <Button className="swiper-button-next" top="unset" marginTop="unset">
            <Icon name="carouselChevronRight" h="14px" w="14px" />
          </Button>
        </Flex>
      </Flex>

      <Box
        borderWidth="1px"
        borderRadius="10px"
        borderColor="#D2D2D4"
        padding={{ base: "8px 20px", md: "12px 24px" }}
        sx={{
          ".swiper-slide.swiper-slide-active": {
            opacity: "1 !important",
            transition: "opacity .3s",
          },
          ".swiper-slide": {
            opacity: "0 !important",
          },
        }}
      >
        <Swiper
          lazy
          modules={[EffectFade, Navigation]}
          effect="fade"
          simulateTouch={false}
          shortSwipes={false}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            disabledClass: "swiper-disabled",
          }}
        >
          {filteredLiveProducts.map((product, index: number) => (
            <SwiperSlide key={`cart-upsell-product-${index}`}>
              <CartUpsellProductCard product={product} key={`${index}-${product?.handle}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
})
