import React, { memo } from "react"
import { Box, Button } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import "swiper/css/navigation"

import { GiftWithPurchaseProduct } from "@app/components/Cart/GiftWithPurchase/GiftWithPurchaseProduct"

import "swiper/css"

type Props = {
  products: any[]
}

export const GiftWithPurchaseSwiper: React.FC<Props> = memo(({ products }) => (
  <Box pos="relative" maxWidth={"75%"} mx="auto">
    <Button className="swiper-button-prev" position="absolute" top="50%" left="-20px" transform="translateY(-50%)" />

    <Swiper
      cssMode
      modules={[Navigation]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={15}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        disabledClass: "swiper-disabled",
      }}
    >
      {products.map((p: any, i: number) => (
        <SwiperSlide key={`gwp-product-${i}`}>
          <GiftWithPurchaseProduct product={p} />
        </SwiperSlide>
      ))}
    </Swiper>

    <Button className="swiper-button-next" position="absolute" top="50%" right="-20px" transform="translateY(-50%)" />
  </Box>
))
