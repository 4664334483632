import React from "react"
import { Flex, Box, BoxProps } from "@chakra-ui/react"

type Props = {
  height?: any
} & BoxProps

const Stripes: React.FC<Props> = ({ height = "5px", ...props }) => {
  const colors = ["brand.offBlue", "brand.blue", "brand.teal", "brand.green", "brand.gold", "brand.red"]

  return (
    <Flex height={height} {...props}>
      {colors?.map((color: string, index: number) => (
        <Box key={index} flex={1} bgColor={color} />
      ))}
    </Flex>
  )
}

export default React.memo(Stripes)
