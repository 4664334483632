import { createBreakpoints } from "@chakra-ui/theme-tools"

export const breakpoints = createBreakpoints({
  xs: "600px",
  sm: "800px",
  md: "1000px",
  lg: "1200px",
  xl: "1400px",
  gridXl: "1440px",
  "2xl": "1600px",
})
