import { useCallback, useState } from "react"

type Props = {
  initialValue?: string
  validateFn?: (value: any) => boolean
  callbackFn?: VoidFunction
  trim?: boolean
  label?: string
  errorMessage?: string
}
export const useInput = ({ label, initialValue, validateFn, callbackFn, trim = true, errorMessage }: Props) => {
  const [value, setValue] = useState(initialValue ?? "")
  const [isValid, setIsValid] = useState<boolean>(true)

  const clear = () => {
    setValue("")
    if (validateFn?.(value) === true) setIsValid(true)
  }

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      let newValue: any
      if (event.target instanceof HTMLInputElement && event.target.type === "checkbox") {
        newValue = event.target.checked.toString()
      } else {
        newValue = event.target.value
      }
      setValue(newValue)
      if (validateFn?.(newValue) === true) setIsValid(true)
      callbackFn?.()
    },
    [validateFn, callbackFn]
  )

  const validate = () => {
    if (!validateFn) return true
    const isValid = validateFn(value)
    setIsValid(isValid)
    return isValid
  }

  const onBlur = useCallback(() => {
    if (trim) setValue(value?.trim())
    if (validateFn) setIsValid(validateFn(value))
  }, [trim, validateFn, value])

  return {
    value,
    onChange,
    setValue,
    isValid,
    label,
    errorMessage,
    validateFn,
    callbackFn,
    clear,
    validate,
    reset: () => setValue(""),
    bind: {
      value,
      onChange,
      onBlur,
    },
  }
}

export const useIsFormValid = () => {
  /**
   * Validate an array of useInput fields and return true if they are all valid
   */
  const isFormValid = (fields: ReturnType<typeof useInput>[]) => fields.map(field => field.validate())?.filter(v => !v).length === 0

  return isFormValid
}
