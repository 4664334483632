import { useMemo, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useRoutes } from "@app/hooks/useRoutes"

export const useNavigation = () => {
  const { anyUrlResolver } = useRoutes()

  const { menus } = useStaticQuery<GatsbyTypes.StaticMenuQuery>(graphql`
    query StaticMenu {
      menus: sanitySettingMenus {
        account {
          items {
            ... on SanityFooterLink {
              ...FooterLinkFragment
            }
          }
        }
        desktopPrimary {
          items {
            ... on SanityObjectNewHeaderMega {
              _key
              _type
              title
              items {
                ... on SanityNewHeaderMegaFeatured {
                  ...HeaderMegaFeaturedFragment
                }
                ... on SanityNewHeaderMegaLinks {
                  ...HeaderMegaLinksFragment
                }
                ... on SanityNewHeaderMegaWorlds {
                  ...HeaderMegaWorldsFragment
                }
                ... on SanityNewHeaderMegaImage {
                  ...HeaderMegaImageFragment
                }
                ... on SanityNewHeaderMegaLink {
                  ...HeaderMegaLinkFragment
                }
              }
            }
            ... on SanityNewHeaderDropdown {
              ...HeaderDropdownFragment
            }
            ... on SanityHeaderLink {
              ...HeaderLinkFragment
            }
          }
        }
        mobilePrimary {
          items {
            ... on SanityObjectMobileLevel1 {
              ...MenuLevel1Fragment
            }
          }
        }
        mobileSecondary {
          items {
            ... on SanityObjectMobileSecondary {
              ...MenuMobileSecondary
            }
          }
        }
        footerPrimary {
          items {
            ... on SanityFooterColumn {
              ...FooterColumnFragment
            }
          }
        }
        footerSecondary {
          items {
            ... on SanityFooterLink {
              ...FooterLinkFragment
            }
          }
        }
      }
    }
  `)

  const buildItems: any = useCallback(
    (items: any) =>
      items?.map((link: any) => {
        const type = link?._type
        const common = { id: link?._key, type, refTitle: link?.referenceTitle }
        switch (type) {
          case "footerColumn": {
            return {
              ...common,
              items: buildItems(link?.items) || null,
            }
          }
          case "footerLink": {
            const resolvedUrl = anyUrlResolver(link?.link)
            return {
              ...common,
              hoverColor: link?.hoverColor,
              ...(resolvedUrl ? resolvedUrl : {}),
            }
          }
          case "footerTitle": {
            return {
              ...common,
              title: link?.title,
            }
          }
          default: {
            return { ...common }
          }
        }
      }) || [],
    [anyUrlResolver]
  )

  const desktopPrimary = useMemo(() => menus?.desktopPrimary?.items, [menus])
  const mobilePrimary = useMemo(() => menus?.mobilePrimary?.items, [menus])
  const mobileSecondary = useMemo(() => menus?.mobileSecondary?.items, [menus])
  const footerPrimary = useMemo(() => buildItems(menus?.footerPrimary?.items), [menus, buildItems])
  const footerSecondary = useMemo(() => buildItems(menus?.footerSecondary?.items), [menus, buildItems])
  const account = useMemo(() => buildItems(menus?.account?.items), [menus, buildItems])

  return { desktopPrimary, mobilePrimary, mobileSecondary, footerPrimary, footerSecondary, account }
}
