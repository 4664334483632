import { useStaticQuery, graphql } from "gatsby"
import { useOptimizely } from "./useOptimizely"

export const useAnnouncement = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        announcements {
          ...LinkFragment
        }
        backgroundColor {
          preset
        }
      }
    }
  `)
  const { isActiveOptimizlyExperiment } = useOptimizely("optimiselyAlertBar")

  let items = data?.announcements || []
  // Optimisely: Subscribe via alert bar or side widget - alert bar START
  items = isActiveOptimizlyExperiment ? items : items.filter(item => item?.type !== "activatesSubscribePopup")
  // Optimisely: Subscribe via alert bar or side widget - alert bar END

  return {
    items,
    defaultBgColour: data?.backgroundColor?.preset || "#F6FCF0",
  }
}
