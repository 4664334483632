import React, { useCallback, useState } from "react"
import { Box, Button, Flex, Text, AspectRatio, Image } from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"
import { useConfigContext } from "@app/providers/config"
import { useShopifyPrice } from "@app/hooks/useShopify"
import { useShopifyImage } from "@app/hooks/useShopifyImage"
import "swiper/css/navigation"
import "swiper/css/effect-fade"
import { NormalisedProduct } from "@root/types/product"

export const CartUpsellProductCard: React.FC<{ product: NormalisedProduct }> = React.memo(({ product }) => {
  const { addToCart, loading } = useCart()
  const { formattedPrice, formattedCompareAtPrice, onSale } = useShopifyPrice(product?.variants?.[0])
  const { scaledUrl: variantImage } = useShopifyImage(product.variants?.[0]?.image?.src, "112x142")
  const [btnDisabled, setBtnDisabled] = useState(false)
  const {
    settings: { cart },
  } = useConfigContext()

  const handleAddToCart = useCallback(async () => {
    if (!loading && !btnDisabled) {
      setBtnDisabled(true)
      await addToCart(product, product?.variants?.[0], 1, [
        { key: cart.giftUpsellText, value: product?.additionalText },
        { key: cart.giftUpsellTextColor, value: product?.additionalTextColor },
      ])
      setBtnDisabled(false)
    }
  }, [loading, btnDisabled, addToCart, product, cart.giftUpsellText, cart.giftUpsellTextColor])

  return product ? (
    <Box>
      <Flex>
        <Box maxWidth={{ base: "56px", sm: "60px" }} width="full" mr="12px">
          <AspectRatio ratio={56 / 71}>
            {variantImage ? <Image src={variantImage} w="100%" h="100%" objectFit="unset !important" /> : <Box />}
          </AspectRatio>
        </Box>
        <Box mr="12px">
          {product?.additionalText && (
            <Text mb="10px" variant="text12" textTransform="uppercase" fontWeight={500} color={product?.additionalTextColor}>
              {product.additionalText}
            </Text>
          )}
          {product?.title && (
            <Text fontSize={{ base: "14px", md: "16px" }} mb="16px" fontWeight={500}>
              {product.title}
            </Text>
          )}
          <Flex direction="row">
            {onSale && (
              <Text
                fontSize={{ base: "14px", md: "15px" }}
                fontWeight={500}
                textDecoration="line-through"
                color="brand.grayDisabled"
                mr={1.5}
              >
                {formattedCompareAtPrice}
              </Text>
            )}
            <Text fontSize={{ base: "14px", md: "15px" }} fontWeight={500} color={onSale ? "brand.red" : "brand.darkBlue"}>
              {formattedPrice}
            </Text>
          </Flex>
        </Box>
        <Flex alignItems="center" marginLeft="auto">
          <Button
            variant="fillRainbowLoader"
            minWidth="70px"
            fontSize="12px"
            lineHeight="14px"
            onClick={() => handleAddToCart()}
            disabled={btnDisabled}
          >
            {btnDisabled ? "Adding" : "Add"}
          </Button>
        </Flex>
      </Flex>
    </Box>
  ) : null
})
