import { gql } from "@apollo/client"

import { PRODUCT_FRAGMENT } from "../fragments/productFragment"
import { COLLECTION_FRAGMENT } from "../fragments/collectionFragment"

export const GET_COLLECTION_PRODUCT_COMPLETE = gql`
  query (
    $countryCode: CountryCode!
    $handle: String!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstProducts: Int
    $firstVariants: Int
    $afterVariants: String
  ) @inContext(country: $countryCode) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts) {
        edges {
          node {
            ...LocalProductFragment
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`
