import React, { useCallback } from "react"
import { Box, Text, Flex, Button } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { useShopify } from "@app/hooks/useShopify"

export const CartMiniOrderWarning: React.FC = () => {
  const { formatMoney } = useShopify()
  const { state, dispatch } = useAppContext()
  const {
    store: { minimumOrderValue },
  } = useConfigContext()

  const onClose = useCallback(() => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state.activeCart])

  return (
    <Flex justifyContent="center" marginTop={"1rem"}>
      <Box
        bg="brand.palepink"
        textAlign={"center"}
        borderWidth="1px"
        paddingBottom={"0.94rem"}
        width={"95%"}
        borderRadius="0.25rem"
        borderColor="brand.red"
      >
        <Text fontSize="0.75rem" marginTop={"1rem"}>
          The total items in your cart are below the minimum order value of {formatMoney(minimumOrderValue)}.
        </Text>
        <Text fontSize="0.75rem">Add more awesome items to your cart to proceed.</Text>
        <Button
          marginTop={"0.75rem"}
          fontSize="0.75rem"
          onClick={onClose}
          lineHeight={"0.875rem"}
          fontWeight={500}
          textDecoration="underline"
        >
          Continue Shopping
        </Button>
      </Box>
    </Flex>
  )
}
