import React, { useCallback, useState } from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import FocusTrap from "focus-trap-react"

import NavigationHeaderMegaLevel2Link from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2Link"
import NavigationHeaderMegaLevel2Content from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2Content"
import NavigationHeaderMegaLevel2World from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2World"
import NavigationHeaderMegaLevel2Featured from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2Featured"
import NavigationHeaderMegaLevel2Image from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2Image"

type Props = {
  item: GatsbyTypes.SanityNewHeaderMegaLinks
  maxHeight?: string
  handleOutsideClick: () => void
  handleLinkClick: () => void
  handleClose?: () => void
  trapped: boolean
  mega?: boolean
}

const NavigationHeaderMegaLevel2: React.FC<Props> = ({
  item,
  mega = true,
  maxHeight,
  trapped,
  handleOutsideClick,
  handleLinkClick,
  handleClose,
}) => {
  const visible = !!item

  const fullWidthContent = !item?.contentRight?.contentType

  const [activeLink, setActiveLink] = useState(null)

  const onMenuClick = useCallback(
    (e: React.MouseEvent) => {
      if (visible) {
        e.stopPropagation()
      }
    },
    [visible]
  )

  const handleActiveLink = useCallback(state => {
    setActiveLink(state)
  }, [])

  return (
    <Box
      pointerEvents="inherit"
      pos="absolute"
      maxHeight={maxHeight}
      top={mega ? 15 : 0}
      bottom={0}
      left={0}
      right={0}
      opacity={visible ? 1 : 0}
      onClick={handleOutsideClick}
      overflowY="auto"
    >
      {item ? (
        <FocusTrap
          active={visible && trapped}
          focusTrapOptions={{
            onPostDeactivate: handleClose,
            initialFocus: false,
            allowOutsideClick: true,
          }}
        >
          <Box>
            {/* NAV FEATURED SECTION */}
            {item?._type === "newHeaderMegaFeatured" && (
              <Flex
                w="100%"
                bgColor={item?.accentColor?.preset || "preset.white"}
                pos="absolute"
                top={0}
                left={0}
                onClick={onMenuClick}
                display={!item ? "none" : "flex"}
                alignItems="center"
                justifyContent="center"
                pt={10}
                pb={14}
                px={{
                  md: 11.5,
                  lg: 16,
                }}
                gap={4}
              >
                {item?.items?.map(featuredItem => (
                  <NavigationHeaderMegaLevel2Featured key={featuredItem._key} item={featuredItem} handleLinkClick={handleLinkClick} />
                ))}
              </Flex>
            )}
            {/* NAV WORLD SECTION */}
            {item?._type === "newHeaderMegaWorlds" && (
              <Flex
                w="100%"
                minHeight={100}
                bgColor={item?.accentColor?.preset || "preset.white"}
                pos="absolute"
                top={0}
                left={0}
                onClick={onMenuClick}
                display={!item ? "none" : "flex"}
                alignItems="center"
                py={10}
                px={14}
                gap={4}
              >
                {item?.items?.map(worldItem => (
                  <NavigationHeaderMegaLevel2World key={worldItem?._key} item={worldItem} handleLinkClick={handleLinkClick} />
                ))}
              </Flex>
            )}

            {/* NAV LINKS SECTION */}
            {item?._type === "newHeaderMegaLinks" && (
              <Flex
                w="100%"
                minHeight={100}
                bgColor={item?.accentColor?.preset || "preset.worldLightBlueAccent"}
                bgGradient={item?.accentColor?.preset || "preset.worldLightBlueAccent"}
                pos="absolute"
                top={0}
                left={0}
                onClick={onMenuClick}
                display={!item ? "none" : "flex"}
                alignItems="flex-start"
              >
                <Flex flexDirection="column" flex={fullWidthContent ? 2 : 2.5} pl={14} pr={6} pt={8} pb={12} gap={2.5} alignSelf="stretch">
                  <Text fontSize="xs">{item?.title}</Text>
                  {item?.items?.map((link, index) => (
                    <NavigationHeaderMegaLevel2Link
                      key={`${index}-${item?._key}`}
                      item={link}
                      handleLinkClick={handleLinkClick}
                      handleActiveLink={handleActiveLink}
                      activeLink={activeLink}
                    />
                  ))}
                </Flex>

                {item?.contentLeft?.contentType && (
                  <Flex
                    flex={fullWidthContent ? 5 : 3}
                    width="100%"
                    py={item?.contentLeft?.objectNavSubLinksSize === "large" && item?.contentRight?.contentType === "links" ? 8 : 10}
                    px={{ base: 10, lg: 14 }}
                    pr={{ base: fullWidthContent ? 10 : 4, lg: fullWidthContent ? 14 : 2 }}
                  >
                    <NavigationHeaderMegaLevel2Content
                      item={item?.contentLeft}
                      handleLinkClick={handleLinkClick}
                      fullWidth={fullWidthContent}
                      itemColour={item?.primaryColor}
                    />
                  </Flex>
                )}
                {/* Removed Middle Column, potential reuse down the line when they want the 3 columns */}

                {/* {item?.contentMiddle?.contentType && (
                  <Flex
                    flex={3}
                    width="100%"
                    py={item?.contentMiddle?.objectNavSubLinksSize === "large" && item?.contentMiddle?.contentType === "links" ? 8 : 10}
                    pr={
                      item?.contentMiddle?.contentType === "links"
                        ? { base: fullWidthContent ? 10 : 4, lg: fullWidthContent ? 14 : 2 }
                        : { base: 10, lg: 14 }
                    }
                    pl={{ base: 4, lg: 0 }}
                  >
                    <NavigationHeaderMegaLevel2Content
                      item={item?.contentMiddle}
                      handleLinkClick={handleLinkClick}
                      itemColour={item?.primaryColor}
                    />
                  </Flex>
                )} */}
                {item?.contentRight?.contentType && (
                  <Flex
                    flex={3}
                    width="100%"
                    py={item?.contentRight?.objectNavSubLinksSize === "large" && item?.contentRight?.contentType === "links" ? 8 : 10}
                    pr={{ base: 10, lg: 14 }}
                    pl={{ base: 4, lg: 0 }}
                  >
                    <NavigationHeaderMegaLevel2Content
                      item={item?.contentRight}
                      handleLinkClick={handleLinkClick}
                      itemColour={item?.primaryColor}
                    />
                  </Flex>
                )}
              </Flex>
            )}

            {item?._type === "newHeaderMegaImage" && (
              <Flex
                w="100%"
                minHeight={400}
                bgColor={item?.accentColor?.preset || "preset.worldLightBlueAccent"}
                bgGradient={item?.accentColor?.preset || "preset.worldLightBlueAccent"}
                pos="absolute"
                top={0}
                left={0}
                onClick={onMenuClick}
                display={!item ? "none" : "flex"}
                alignItems="flex-start"
              >
                <NavigationHeaderMegaLevel2Image item={item} handleLinkClick={handleLinkClick} />
              </Flex>
            )}
          </Box>
        </FocusTrap>
      ) : null}
    </Box>
  )
}

export default React.memo(NavigationHeaderMegaLevel2)
