import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLiveProducts } from "@app/hooks/useLiveProducts"
import { NormalisedProduct } from "@root/types/product"

export const useCartUpsell = () => {
  const { cartUpsellSettings } = useStaticQuery<GatsbyTypes.StaticCartUpsellQuery>(graphql`
    query StaticCartUpsell {
      cartUpsellSettings: sanitySettingCartUpsell {
        title
        cartUpsellEnabled
        upsellProducts {
          _rawProduct(resolveReferences: { maxDepth: 2 })
          additionalText
          additionalTextColor {
            hex
          }
        }
      }
    }
  `)

  const { cartUpsellEnabled, title, upsellProducts } = cartUpsellSettings || {}

  const rawProducts = useMemo(
    () =>
      upsellProducts?.map((product: GatsbyTypes.SanityCartUpsellItem) => ({
        ...product?._rawProduct,
        additionalText: product?.additionalText || "",
        additionalTextColor: product?.additionalTextColor?.hex || "#1c1f2a",
      })) || [],
    [upsellProducts]
  )

  const { liveProducts } = useLiveProducts(rawProducts)

  const products = liveProducts.map((liveProduct: NormalisedProduct) => {
    const staticProduct = rawProducts.find(({ shopify }) => shopify.handle === liveProduct.handle)

    return {
      ...liveProduct,
      additionalText: staticProduct?.additionalText,
      additionalTextColor: staticProduct?.additionalTextColor,
    }
  })

  return {
    cartUpsellEnabled,
    title,
    products,
  }
}
