export const space = {
  0.25: "0.0625rem",
  0.5: "0.125rem",
  1: "0.25rem",
  1.25: "0.3125rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  3.75: "0.9375rem",
  4: "1rem",
  4.5: "1.125rem",
  4.75: "1.1875rem",
  5: "1.25rem",
  6: "1.5rem",
  6.25: "1.5625rem",
  7: "1.75rem",
  7.5: "1.875rem",
  8: "2rem",
  8.5: "2.125rem",
  8.75: "2.1875rem",
  9: "2.25rem",
  9.5: "2.375rem",
  10: "2.5rem",
  10.25: "2.5625rem",
  10.5: "2.625rem",
  11.5: "2.875rem",
  12: "3rem",
  12.5: "3.125rem",
  13: "3.25rem",
  14: "3.5rem",
  15: "3.75rem",
  16: "4rem",
  16.25: "4.063rem",
  18: "4.5rem",
  20: "5rem",
  21.25: "5.313rem",
  22: "5.5rem",
  24: "6rem",
  25: "6.25rem",
  28: "7rem",
  31: "7.75rem",
  32: "8rem",
  36: "9rem",
  37.5: "9.375rem",
  40: "10rem",
  43.75: "10.938rem",
  44: "11rem",
  48: "12rem",
  50: "12.5rem",
  51.5: "12.875rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  87.5: "21.875rem",
  93: "23.25rem",
  96: "24rem",
  100: "25rem",
  107.5: "26.875rem",
  112.5: "28.125rem",
  137.5: "34.375rem",
  150: "37.5rem",
  200: "50rem",
  275: "68.75rem",
}
