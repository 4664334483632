import gql from "graphql-tag"

import { VARIANT_FRAGMENT } from "@app/graphql/fragments/variantFragment"
import { IMAGE_FRAGMENT } from "@app/graphql/fragments/imageFragment"

export const GET_MINIMAL_PRODUCT = gql`
  query ($countryCode: CountryCode!, $handle: String!) @inContext(country: $countryCode) {
    product: productByHandle(handle: $handle) {
      handle
    }
  }
`

export const GET_PRODUCTS_BY_HANDLE_LIGHT = (handles: Array<string>) => gql`
  query(
    $countryCode: CountryCode!
    $firstImages: Int
    $firstVariants: Int
  ) @inContext(country: $countryCode) {
    ${handles?.map(
      handle => `
      product${handle.replace(/-/g, "")}: productByHandle(handle: "${handle}") {
        id
        handle
        title
        description
        tags
        images(first: $firstImages) {
          edges {
            node {
              ...ImageFragment
            }
          }
        }
        variants(first: $firstVariants) {
          edges {
            node {
              ...VariantFragment
            }
          }
        }
      }
    `
    )}
  }
  ${IMAGE_FRAGMENT}
  ${VARIANT_FRAGMENT}
`
