import React, { useState } from "react"
import TvLoading from "@app/components/Tv/TvLoading"
import { useCore } from "@app/hooks/useCore"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "@chakra-ui/react"

const TvSplash: React.FC = () => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const [loaded, setLoaded] = useState(false)
  const { tv } = useStaticQuery<GatsbyTypes.TvSplashQuery>(graphql`
    query TvSplash {
      tv: sanityPageTv {
        splashVideo: _rawSplashVideo(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  return !loaded ? (
    <Box pos="fixed" inset={0} zIndex={1000}>
      <Box pos="absolute" inset={0} bgColor="brand.darkBlue" />
      {isBrowser && <TvLoading url={tv?.splashVideo?.asset?.url} loadTime={3000} handleLoad={() => setLoaded(true)} />}
    </Box>
  ) : null
}

export default TvSplash
