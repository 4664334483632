export const Checkbox = {
  baseStyle: {
    label: {
      _invalid: {
        color: "preset.worldRed",
      },
    },
    icon: {
      background: "primary.black",
    },
  },
  sizes: {
    lg: {
      label: {
        fontSize: "xs",
      },
    },
  },
}
