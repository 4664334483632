import { graphql, useStaticQuery } from "gatsby"
import { useCallback } from "react"
import { useCore } from "./useCore"

export const useDiscounts = () => {
  const {
    helpers: { edgeNormaliser },
  } = useCore()

  const { discounts: data } = useStaticQuery(graphql`
    query StaticDiscounts {
      discounts: allSanitySettingDiscounts {
        edges {
          node {
            title
            tags
            enabled
            themeColourText {
              theme
              custom {
                hex
              }
            }
          }
        }
      }
    }
  `)

  const getDiscountsFromTags = useCallback(
    (tags?: string[]) =>
      (edgeNormaliser(data) as GatsbyTypes.SanitySettingDiscounts[]).filter(discount =>
        discount.tags?.find(tag => tag && tags?.includes(tag))
      ),
    [edgeNormaliser, data]
  )

  return {
    getDiscountsFromTags,
  }
}
