import gql from "graphql-tag"

import { MONEY_FRAGMENT } from "@app/graphql/fragments/moneyFragment"
import { VARIANT_WITH_PRODUCT_FRAGMENT } from "@app/graphql/fragments/variantWithProductFragment"

export const CART_FRAGMENT = gql`
  fragment CartFragment on Cart {
    attributes {
      key
      value
    }
    buyerIdentity {
      countryCode
    }
    checkoutUrl
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    createdAt
    discountAllocations {
      discountedAmount {
        ...MoneyFragment
      }
    }
    discountCodes {
      applicable
      code
    }
    id
    lines(first: 250) {
      edges {
        node {
          attributes {
            key
            value
          }
          cost {
            amountPerQuantity {
              ...MoneyFragment
            }
            compareAtAmountPerQuantity {
              ...MoneyFragment
            }
            subtotalAmount {
              ...MoneyFragment
            }
            totalAmount {
              ...MoneyFragment
            }
          }
          discountAllocations {
            discountedAmount {
              ...MoneyFragment
            }
          }
          id
          merchandise {
            ...VariantWithProductFragment
          }
          quantity
          ... on ComponentizableCartLine {
            lineComponents {
              id
              merchandise {
                ...VariantWithProductFragment
              }
              quantity
              cost {
                totalAmount {
                  ...MoneyFragment
                }
              }
            }
          }
        }
      }
    }
    note
    totalQuantity
    updatedAt
  }
  ${MONEY_FRAGMENT}
  ${VARIANT_WITH_PRODUCT_FRAGMENT}
`
