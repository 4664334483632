import { useStaticQuery, graphql } from "gatsby"

export const useGeoLocation = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticBackInStockQuery>(graphql`
    query StaticGelocation {
      data: sanitySettingGeolocation {
        title
        emojis
        content
        button
        image: _rawImage(resolveReferences: { maxDepth: 1 })
      }
    }
  `)

  const title = data?.title || null
  const emojis = data?.emojis || null
  const content = data?.content || null
  const image = data?.image || null
  const button = data?.button || null

  return {
    title,
    emojis,
    content,
    image,
    button,
  }
}
