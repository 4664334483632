import React, { useCallback } from "react"
import { Button } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

type Props = {
  item: GatsbyTypes.SanityHeaderLink
}

const NavigationHeaderItem: React.FC<Props> = ({ item }) => {
  const { dispatch } = useAppContext()
  const { anyUrlResolver } = useRoutes()
  const resolvedLink = anyUrlResolver(item?.link)

  const onClick = useCallback(() => {
    dispatch({
      type: "header",
      payload: null,
    })
  }, [dispatch])

  return (
    <Button variant={"headerItem"} as={Link} to={resolvedLink?.url} onClick={onClick}>
      {resolvedLink?.title}
    </Button>
  )
}

export default React.memo(NavigationHeaderItem)
