import React, { useCallback } from "react"
import { Box, Button, Flex, Heading, Image } from "@chakra-ui/react"

import { useCartUpsell } from "@app/hooks/useCartUpsell"
import { useAppContext } from "@app/providers/app"
import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { CartItem } from "@app/components/Cart/CartItem"
import { CartUpsell } from "@app/components/Cart/CartUpsell"
import Link from "@app/components/Link"
import MotionBox from "@app/components/MotionBox"
import Intergalactic from "@static/images/intergalactic.png"
import TotallyClear from "@static/images/totally-clear.png"

type Props = {
  drawer?: boolean
}

export const CartList: React.FC<Props> = React.memo(({ drawer = false }) => {
  const { settings } = useConfigContext()
  const { cart, count } = useCartContext()
  const { state, dispatch } = useAppContext()

  const { cartUpsellEnabled } = useCartUpsell()

  const onClose = useCallback(() => {
    dispatch({
      type: "cart",
      payload: !state.activeCart,
    })
  }, [dispatch, state.activeCart])

  const productArray = cart?.lines?.sort((left, right) => {
    const leftAttribute = left.attributes.find(
      object => object.key == settings.cart.gwpAttribute || object.key == settings.cart.giftWrapAttribute
    )
    const rightAttribute = right.attributes.find(object => object.key == settings.cart.gwpAttribute)
    if (leftAttribute) {
      return -1
    } else if (rightAttribute) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <Box>
      {count > 0 ? (
        <Box px={5} pb={4}>
          {productArray?.map(item => (
            <CartItem key={item?.id} item={item} />
          ))}
          {cartUpsellEnabled && <CartUpsell />}
        </Box>
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexDirection="column"
          p={drawer ? "20px" : "unset"}
          pb={drawer ? "20px" : [6, 24]}
          w={drawer ? "100%" : "unset"}
          h={drawer ? "100%" : "unset"}
          pos={drawer ? "absolute" : "unset"}
          top={drawer ? 0 : "unset"}
          left={drawer ? 0 : "unset"}
          bottom={drawer ? 0 : "unset"}
          right={drawer ? 0 : "unset"}
        >
          <Box>
            <Heading as="p" variant="h3" fontSize="xl" fontWeight={500}>
              Your bag is empty :(
            </Heading>
            <Button as={Link} to={settings.routes.HOMEPAGE} mt={6} onClick={onClose} variant="fill" minW="234px">
              Shop Allkinds
            </Button>
            {drawer && (
              <>
                <Box pos="absolute" bottom={["10%", "15%"]} left={[8, 16]}>
                  <MotionBox
                    animate={{
                      rotate: ["0deg", "20deg"],
                      transition: {
                        duration: 0.5,
                        repeat: Infinity,
                        repeatType: "reverse",
                      },
                    }}
                  >
                    <Image src={Intergalactic} width={["70px", "122px"]} />
                  </MotionBox>
                </Box>
                <Box pos="absolute" top={["10%", "15%"]} right={[8, 16]}>
                  <MotionBox
                    animate={{
                      rotate: ["0deg", "-20deg"],
                      transition: {
                        duration: 0.5,
                        repeat: Infinity,
                        repeatType: "reverse",
                      },
                    }}
                  >
                    <Image src={TotallyClear} width={["101px", "175px"]} />
                  </MotionBox>
                </Box>
              </>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  )
})
