import { Input } from "."

export const Textarea = {
  baseStyle: {
    ...Input.baseStyle.field,
  },
  sizes: {
    default: {},
  },
  variants: {
    outline: {
      ...Input.variants.outline.field,
    },
    giftCard: {
      ...Input.variants.giftCard.field,
    },
    giftCardAlt: {
      ...Input.variants.giftCardAlt.field,
    },
  },
  defaultProps: {
    size: "default",
    variant: "outline",
  },
}
