import React from "react"
import { Box, Button } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

type Props = {
  item: GatsbyTypes.SanityLink | null
  handleLinkClick: () => void
  handleActiveLink: (state: any) => void
  activeLink: any
}

const NavigationHeaderMegaLevel2Link: React.FC<Props> = ({ item, activeLink, handleLinkClick, handleActiveLink }) => {
  const { anyUrlResolver } = useRoutes()

  const resolvedLink = anyUrlResolver(item)

  const isActiveLink = activeLink?._key === item?._key

  return (
    <Box>
      <Button
        variant="headerMegaItemLevel2"
        as={Link}
        to={resolvedLink?.url}
        justifyContent="flex-start"
        onClick={() => {
          handleLinkClick?.()
        }}
        onMouseEnter={() => handleActiveLink(item)}
        onMouseLeave={() => handleActiveLink(null)}
        opacity={isActiveLink || !activeLink ? 1 : 0.6}
      >
        {item?.title}
      </Button>
    </Box>
  )
}

export default React.memo(NavigationHeaderMegaLevel2Link)
