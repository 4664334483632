import React from "react"
import { Box, Heading, Text, Flex, Image } from "@chakra-ui/react"
import { useImageResolver } from "@app/hooks/useImageResolver"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

type Props = {
  item: any
  handleLinkClick?: () => void
}

const NavigationHeaderMegaLevel2Image: React.FC<Props> = ({ item, handleLinkClick }) => {
  const { fontColor, title, description, showCTAButton } = item
  const { resolvedImage: banner } = useImageResolver(item.image)
  const { anyUrlResolver } = useRoutes()
  const resolvedLink = anyUrlResolver(item.link)

  return (
    <Flex
      as={!showCTAButton && resolvedLink ? Link : "div"}
      to={resolvedLink?.url}
      onClick={() => {
        if (!showCTAButton) {
          handleLinkClick?.()
        }
      }}
      tabIndex={0}
      position="relative"
      w="100%"
      alignSelf="stretch"
    >
      {banner && <Image src={banner?.src} alt={item.image?.alt} w="100%" h="100%" objectFit="cover" />}
      <Flex position="absolute" top="0" left="0" width="100%" justifyContent="center">
        <Box position="relative" width="100%" maxWidth="1440px">
          <Box position="absolute" top="0" left="0" paddingY={{ md: "16", lg: "72px" }} paddingX={{ md: "56px" }} width="50%">
            {title && (
              <Heading
                variant="h2"
                fontWeight={700}
                lineHeight="80px"
                fontSize="64px"
                color={fontColor === "light" ? "brand.white" : "initial"}
              >
                {title}
              </Heading>
            )}
            {description && (
              <Text marginBottom={resolvedLink && showCTAButton ? "24px" : "0"} color={fontColor === "light" ? "brand.white" : "initial"}>
                {description}
              </Text>
            )}
            {resolvedLink && showCTAButton && (
              <Link
                to={resolvedLink.url}
                _hover={{
                  textDecoration: "none",
                }}
                onClick={() => {
                  handleLinkClick?.()
                }}
              >
                <Text
                  as="span"
                  position="relative"
                  color={fontColor === "light" ? "brand.white" : "initial"}
                  _hover={{
                    textDecoration: "none",
                  }}
                  _after={{
                    content: "''",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: "-4px",
                    height: "4px",
                    width: "100%",
                    bgImage: "url(/images/wavy-orange.svg)",
                    backgroundRepeat: "repeat-x",
                    color: "brand.orange",
                  }}
                >
                  {resolvedLink.title ? resolvedLink.title : "Shop the Range"}
                </Text>
              </Link>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default React.memo(NavigationHeaderMegaLevel2Image)
