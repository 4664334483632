import React, { useState, useMemo } from "react"
import { AspectRatio, Box, Text } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import ResolvedImage from "@app/components/ResolvedImage"

type Props = {
  item: GatsbyTypes.SanityNewNavigationWorld
  handleLinkClick?: () => void
}

const NavigationHeaderMegaLevel2World: React.FC<Props> = ({ item, handleLinkClick }) => {
  const { urlResolver } = useRoutes()
  const [isSelected, setIsSelected] = useState(false)
  const resolvedCardLink = useMemo(() => urlResolver(item?.world.linkedCollection), [item?.world.linkedCollection, urlResolver])
  const handleSelection = () => setIsSelected(!isSelected)

  return (
    <Box width="100%" onMouseEnter={handleSelection} onFocus={handleSelection} onBlur={handleSelection} onMouseLeave={handleSelection}>
      <Link to={resolvedCardLink?.url} onClick={handleLinkClick} textDecoration="none !important" _focus={{ boxShadow: "none !important" }}>
        <Box width="100%" position="relative">
          <AspectRatio ratio={208 / 340}>
            <ResolvedImage image={item?.image} borderRadius="8px" loading="lazy" maxImageWidth={275} />
          </AspectRatio>
          {/* Hover State */}
          <Box
            opacity={isSelected ? 1 : 0}
            transition="opacity .3s ease-in-out"
            position="absolute"
            top={0}
            p={4}
            borderRadius="8px"
            w="100%"
            h="100%"
            bgColor={`${item?.color?.preset}BB`}
          >
            <Text color="preset.white" fontSize="xs" fontWeight={700} mb={1.5}>
              {item?.title}
            </Text>
            {item?.textArray?.map((text, index) => (
              <Text key={`${text} - ${index}`} color="preset.white" fontWeight={700} fontSize={{ md: "xl", lg: "32px" }} mb={3}>
                {text}
              </Text>
            ))}
          </Box>
        </Box>
        <Text fontSize="lg" fontWeight={700} color={item?.color?.preset} mt={3}>
          {item?.world?.name}
        </Text>
      </Link>
    </Box>
  )
}

export default React.memo(NavigationHeaderMegaLevel2World)
