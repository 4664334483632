import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"
import { useAnnouncement } from "@app/hooks/useAnnouncement"
import { Flex, Text } from "@chakra-ui/react"
import Link from "@app/components/Link"

import "swiper/css"
import "swiper/css/autoplay"
import { useAppContext } from "@app/providers/app"
import { useRoutes } from "@app/hooks/useRoutes"
import { colors } from "@app/theme/colors"
import { useNewsletter } from "@app/hooks/useNewsletter"
import { useOptimizely } from "@app/hooks/useOptimizely"

import { Location } from "@root/types/global"

type Props = {
  location: Location
}

const Announcement: React.FC<Props> = ({ location }) => {
  const { anyUrlResolver } = useRoutes()
  const { items: rawItems, defaultBgColour } = useAnnouncement()
  const { isActiveOptimizlyExperiment } = useOptimizely("optimiselyAlertBar")
  const { state, dispatch } = useAppContext()
  const { handleOpen } = useNewsletter(location)
  const fontColour = defaultBgColour === colors.preset.default ? "preset.white" : "preset.default"

  const [isVisible, setIsVisible] = useState(false)

  const items = useMemo(() => rawItems?.map(item => ({ ...item, link: anyUrlResolver(item) })), [anyUrlResolver, rawItems])

  const onHeaderToggle = useCallback(() => {
    state.activeHeader &&
      dispatch({
        type: "header",
        payload: null,
      })
  }, [dispatch, state.activeHeader])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true)
    }, 2000)

    return () => clearTimeout(timeoutId)
  }, [isActiveOptimizlyExperiment])

  return items?.length > 1 ? (
    <Flex
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bgColor={defaultBgColour}
      zIndex={1100}
      height="40px"
      onClick={() => onHeaderToggle()}
    >
      <Swiper
        modules={[Autoplay]}
        loop
        slidesPerView={1}
        autoplay={{ delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true }}
        speed={1750}
      >
        {items?.map((item: any, index: number) => (
          <SwiperSlide key={item?._key || index}>
            {item?.type !== "activatesSubscribePopup" ? (
              <Text
                as={item?.link?.url ? Link : "p"}
                to={item?.link?.url}
                textDecoration={item?.link?.url && "underline"}
                variant="text12"
                fontWeight={500}
                color={fontColour}
                display="inline"
                opacity={isVisible ? 1 : 0}
                transition="opacity 0.5s ease"
              >
                {item?.title}
              </Text>
            ) : (
              <Text
                variant="text12"
                fontWeight={500}
                color={fontColour}
                display="inline"
                opacity={isVisible ? 1 : 0}
                transition="opacity 0.5s ease"
                cursor="pointer"
                onClick={handleOpen}
              >
                {item?.title}
              </Text>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Flex>
  ) : (
    <Flex
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bgColor={defaultBgColour}
      zIndex={1100}
      height="40px"
      opacity={isVisible ? 1 : 0}
      transition="opacity 0.5s ease"
    >
      <Text variant="text12" color={fontColour} display="inline" fontWeight={500}>
        {items[0]?.title}
      </Text>
    </Flex>
  )
}

export default React.memo(Announcement)
