import { useCallback } from "react"
import { useAppContext } from "@app/providers/app"

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useAppContext()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products") && product) {
        let currentProduct = product

        try {
          currentProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
        } catch (e) {
          console.error((e as Error).message)
        }

        if (!activeProduct || activeProduct.id !== currentProduct.id) {
          setActiveProduct(currentProduct)
        }
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct]
  )

  return { activeProduct, selectProduct }
}
