import React, { useEffect } from "react"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useAppContext } from "@app/providers/app"
import { useShopifyProduct } from "@app/hooks/useShopifyProduct"
import { useMaintenance } from "@app/hooks/useMaintenance"

import type { Location } from "@root/types/global"
import { useEmarsysContext } from "@app/providers/emarsys"
import { useInsideDataPage, useInsideDataUser } from "@app/hooks/useInsideData"
import "focus-visible/dist/focus-visible"

export const withLayout =
  (Component: React.FC<{ data: any; serverData: any; location: Location }>) =>
  ({ name = "Layout", location, children, data, serverData }: any) => {
    const { active, authorised } = useMaintenance(location)
    const { selectProduct } = useShopifyProduct()
    const { emarsysReady, trackPage } = useEmarsysContext()
    const { trackPageView, tracked } = useAnalytics()
    const { dispatch } = useAppContext()

    useInsideDataUser()
    useInsideDataPage(
      data?.page?.title || serverData?.title || data?.collection?.title || data?.product?.title || "other",
      data?.page?._type || serverData?._type || data?.collection?._type || data?.product?._type || "other"
    )

    useEffect(() => {
      if (tracked) {
        trackPageView()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracked])

    useEffect(() => {
      selectProduct(data?.product, location?.pathname)
      trackPageView()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname])

    useEffect(() => {
      if (emarsysReady) {
        trackPage(data?.collection, data?.product)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.pathname, emarsysReady])

    useEffect(() => {
      dispatch({ type: "initial" })
    }, [location?.pathname, dispatch])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      authorised && (
        <Component data={data} location={location}>
          {children}
        </Component>
      )
    )
  }
