import { useEffect, useState } from "react"

type UseHydration = {
  isHydrated: boolean
}

export const useHydration = (): UseHydration => {
  const [isHydrated, setIsHydrated] = useState(false)

  useEffect(() => {
    setIsHydrated(true)
  }, [])

  return { isHydrated }
}
