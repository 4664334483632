import React from "react"
import { Box, FormControl, FormErrorMessage, Input, Text, FormLabel, Button, Heading } from "@chakra-ui/react"
import { useNewsletter } from "@app/hooks/useNewsletter"
import { useInput } from "@app/hooks/useInput"
import config from "../../../config"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useConfigContext } from "@app/providers/config"
import { formatPhoneNumber } from "@app/hooks/useCustomer"
import Link from "../Link"

type Props = {
  success: boolean
  setSuccess: (value: boolean) => void
  headSize: string
}

const SubscribeFormDetailed: React.FC<Props> = ({ success, setSuccess, headSize }) => {
  const {
    store: { locationLookupUrl },
  } = useConfigContext()
  const { newsletter, handleClose } = useNewsletter()
  const { trackUserIdentity } = useAnalytics()
  const { value: firstName, bind: bindFirstName } = useInput({})
  const { value: lastName, bind: bindLastName } = useInput({})
  const { value: email, bind: bindEmail } = useInput({ trim: true })
  const { value: phone, bind: bindPhone } = useInput({})

  const successText = newsletter?.additionalSuccess || "Thank you for subscribing!"
  const successText2 = newsletter?.additionalSuccess1 || ""
  const termComditionTitle = newsletter?.TCtitle
  const termComditionLink = newsletter?.TClink

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    let formattedPhoneNumber: string

    try {
      const { country_calling_code } = await fetch(locationLookupUrl).then(res => res.json())
      formattedPhoneNumber = formatPhoneNumber(phone, country_calling_code)
    } catch {
      formattedPhoneNumber = phone
    }

    let phoneString = ""
    if (phone) {
      phoneString = `&inp_37=${formattedPhoneNumber.replaceAll(" ", "")}`
    }

    const iframeSrc = `${config.store.emarsysSignupUrl}&inp_1=${firstName}&inp_2=${lastName}&inp_3=${email}&inp_13=${phoneString}`

    const registrationIframe = document.createElement("iframe")

    registrationIframe.style = "width: 0; height: 0; overflow: hidden; position: absolute; top: -1000px; left: -1000px;"
    registrationIframe.src = iframeSrc

    document.body.appendChild(registrationIframe)

    registrationIframe.onload = () => {
      setSuccess(true)
      trackUserIdentity({
        email,
        firstName,
        lastName,
        phone: formattedPhoneNumber,
      })
      registrationIframe.parentNode.removeChild(registrationIframe)
    }
  }

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      w="full"
      mt={3}
      textAlign="left"
      sx={{
        "& label span": {
          ml: 0,
          color: "brand.black",
        },
      }}
    >
      {!success ? (
        <>
          <FormControl mb={3} id="firstName" isRequired>
            <FormLabel fontSize={12} lineHeight="20px">
              First Name
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="First Name" name="firstName" type="text" {...bindFirstName} />
          </FormControl>

          <FormControl mb={3} id="lastName" isRequired>
            <FormLabel fontSize={12} lineHeight="20px">
              Last Name
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="Last Name" name="lastName" type="text" {...bindLastName} />
          </FormControl>

          <FormControl mb={3} id="email" isRequired>
            <FormLabel fontSize={12} lineHeight="20px">
              Email
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="Email" name="email" type="email" {...bindEmail} />
            <FormErrorMessage>Please enter email address.</FormErrorMessage>
          </FormControl>

          <FormControl mb={3} id="phone">
            <FormLabel fontSize={12} lineHeight="20px">
              Phone Number
            </FormLabel>
            <Input variant="subscribeDetail" placeholder="Phone Number" name="phone" type="tel" {...bindPhone} />
          </FormControl>

          <Text variant="text12" m="auto" mt={2} mb={4}>
            {termComditionTitle}
            {termComditionLink?.pageLink && (
              <Link to={"/" + termComditionLink?.pageLink?.handle?.current} external={termComditionLink?.external}>
                {termComditionLink?.title}
              </Link>
            )}
          </Text>

          <Button variant="fillSmall" type="submit" aria-label="Submit" mt={5} px={9} mx="auto" display="flex">
            Join the fun!
          </Button>
        </>
      ) : (
        <>
          <Heading as="p" variant={headSize} lineHeight="28px" p="15px 0" letterSpacing="0.03em" fontWeight="bold" textAlign="center">
            {successText}
            <br />
            {successText2}
          </Heading>
          <Button variant="fillSmall" onClick={handleClose} mt={5} px={9} mx="auto" display="flex">
            Start Shopping
          </Button>
        </>
      )}
    </Box>
  )
}

export default React.memo(SubscribeFormDetailed)
