export const Drawer = {
  baseStyle: {
    overlay: {
      bg: "primary.overlay",
    },
    dialog: {
      bg: "brand.white",
    },
  },
  sizes: {
    md: {
      dialog: { maxW: "150" },
      body: { py: { base: "6", md: "8" }, px: { base: "5", md: "8.75" } },
    },
    navigation: {
      header: { display: "flex", paddingY: "4", paddingLeft: "6", paddingRight: "0" },
      dialog: { maxW: "min(90%,376px)" },
      body: { padding: "0" },
      footer: { padding: "0" },
    },
  },
  defaultProps: {
    size: "md",
  },
}
