import React from "react"
import { Image, ImageProps } from "@chakra-ui/react"
import { useImageResolver } from "@app/hooks/useImageResolver"

type Props = {
  image: any
  maxImageWidth?: number
} & ImageProps

const ResolvedImage: React.FC<Props> = ({ image, maxImageWidth, ...props }) => {
  const { resolvedImage } = useImageResolver(image, maxImageWidth)

  return resolvedImage ? <Image {...resolvedImage} {...props} /> : null
}

export default React.memo(ResolvedImage)
