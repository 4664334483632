import React, { useEffect } from "react"
import { Button, Text, Flex, Box, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure, VStack } from "@chakra-ui/react"

import { useLocationContext } from "@app/providers/location"
import { useAppContext } from "@app/providers/app"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { useGeoLocation } from "@app/hooks/useGeolocation"
import { useMedia } from "@app/hooks/useMedia"
import ResolvedImage from "@app/components/ResolvedImage"
import GeolocationPopupClose from "@app/components/Geolocation/GeolocationPopupClose"

const GeolocationPopup: React.FC = () => {
  const {
    locating,
    country,
    store: { id },
    stores,
  } = useLocationContext()
  const { setStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { isMedium } = useMedia()
  const { title, emojis, content, button, image } = useGeoLocation()
  const { isOpen, onToggle } = useDisclosure()
  const {
    helpers: { isBrowser },
  } = useCore()
  const {
    dispatch,
    state: { geolocationDismissed, geolocationOpen },
  } = useAppContext()

  const targetStore: any = Object.values(stores).find(store => store?.locationCountries.includes(country)) || Object.values(stores)[0]
  const btnText = button?.replace("{region}", targetStore?.locationRegion)
  const contentText = content?.replace("{region}", targetStore?.locationRegionFullName)
  const modalSize = isMedium ? "5xl" : "lg"

  const handleClose = () => {
    dispatch({ type: "geolocationDismissed", payload: true })
    dispatch({ type: "geolocationOpen", payload: false })
    setStorage(keys.geolocationDismissed, true, 7)
    onToggle()
  }

  const handleClick = () => (isBrowser ? (window.location.href = `https://${targetStore.url}`) : null)

  useEffect(() => {
    if (locating) return
    if (isOpen && !geolocationOpen) dispatch({ type: "geolocationOpen", payload: true })
    if (!geolocationDismissed && !isOpen && targetStore?.id !== id) setTimeout(() => onToggle(), 800)
  }, [isOpen, onToggle, dispatch, geolocationDismissed, geolocationOpen, locating, targetStore?.id, id])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size={modalSize}>
      <ModalOverlay background="#00000050" backdropFilter="none" />
      <ModalContent bg="white">
        <GeolocationPopupClose setOpen={handleClose} />
        <ModalBody padding="0" borderRadius="none">
          <Flex direction="row">
            <Flex flex="1 1 0px" display={{ base: "none", md: "block" }}>
              <ResolvedImage image={image} w="100%" h="100%" objectFit="cover" />
            </Flex>
            <Flex flex="1 1 0px" flexDirection="column">
              <VStack spacing="8" textAlign="center" padding={{ base: "60px", md: "80px" }} height="100%" justifyContent="center">
                <Text variant="paragraph20">{title}</Text>
                <Box mt="0 !important">{emojis}</Box>
                <Text variant="paragraph20">{contentText}</Text>
                <Button onClick={handleClick} variant="fill">
                  {btnText}
                </Button>
              </VStack>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(GeolocationPopup)
