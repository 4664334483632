import React, { useRef, useEffect, useState } from "react"
import { Box, Button, Flex } from "@chakra-ui/react"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

type Props = {
  item: GatsbyTypes.SanityHeaderMega
  selectedItem: GatsbyTypes.SanityHeaderMega | null
  visible: boolean
  activeHeaderIndex: number | null
  handleItemClick: (item: any, index: number) => void
  handleLinkClick: () => void
}

const NavigationHeaderMegaLevel1: React.FC<Props> = ({
  item,
  selectedItem,
  visible,
  activeHeaderIndex,
  handleItemClick,
  handleLinkClick,
}) => {
  const itemsRef = useRef<Array<HTMLDivElement | null>>([])
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const { anyUrlResolver } = useRoutes()

  useEffect(() => {
    return () => {
      setIsFirstLoad(true)
    }
  }, [])

  return (
    <Flex
      bgColor={selectedItem?.primaryColor?.preset || "preset.default"}
      w="100%"
      maxHeight={15}
      display="flex"
      justifyContent="center"
      alignItems="center"
      pos="absolute"
      top={0}
      bottom={0}
    >
      {item?.items?.map((item: any, index: number) => {
        const resolvedLink = item._type === "newHeaderMegaLink" ? anyUrlResolver(item?.link) : null
        return (
          <Box key={index} ref={el => (itemsRef.current[index] = el)}>
            {/* isFirstLoad check required to shave off 0.01s from the font render to the correct colour on load only */}
            {item._type === "newHeaderMegaLink" ? (
              resolvedLink ? (
                <Button
                  as={Link}
                  to={resolvedLink?.url}
                  variant="headerMegaItemLevel1"
                  zIndex={100}
                  pointerEvents={visible ? "auto" : "none"}
                  tabIndex={visible ? 0 : -1}
                  _hover={{
                    textDecoration: "none",
                    _after: {
                      w: "full",
                    },
                  }}
                  onClick={() => {
                    setIsFirstLoad(false)
                    handleLinkClick?.()
                  }}
                >
                  {item?.title}
                </Button>
              ) : null
            ) : (
              <Button
                variant={
                  isFirstLoad && index === activeHeaderIndex
                    ? "headerMegaItemLevel1Active"
                    : item?.title === selectedItem?.title
                    ? "headerMegaItemLevel1Active"
                    : "headerMegaItemLevel1"
                }
                zIndex={100}
                pointerEvents={visible ? "auto" : "none"}
                tabIndex={visible ? 0 : -1}
                onClick={e => {
                  e.stopPropagation()
                  setIsFirstLoad(false)
                  handleItemClick?.(item, index)
                }}
              >
                {item?.title}
              </Button>
            )}
          </Box>
        )
      })}
      {activeHeaderIndex !== null && (
        <Box
          position="absolute"
          zIndex={0}
          left={`${itemsRef.current[activeHeaderIndex]?.offsetLeft}px` || 0}
          w={`${itemsRef.current[activeHeaderIndex]?.offsetWidth}px` || 0}
          borderRadius={"24px"}
          bgColor="primary.white"
          h={9}
          transition={`all .2s ease-in-out`}
        />
      )}
    </Flex>
  )
}

export default React.memo(NavigationHeaderMegaLevel1)
