import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const useWorlds = () => {
  const { worlds } = useStaticQuery<GatsbyTypes.SettingWorldsQuery>(graphql`
    query SettingWorlds {
      worlds: allSanitySettingWorlds {
        edges {
          node {
            name
            tags
            linkedCollection {
              ...CollectionLinkFragment
            }
            icon
            plpBackgroundImage {
              ...CustomImageFragment
            }
            color: _rawColor(resolveReferences: { maxDepth: 2 })
            textColor
            order
          }
        }
      }
    }
  `)

  const normalisedWorlds = useMemo(() => worlds?.edges?.map((edge: any) => edge?.node), [worlds])

  const getCollectionWorld: any = (collection: any) =>
    normalisedWorlds?.find(
      (world: any) => collection?.shopify?.handle && collection?.shopify?.handle === world?.linkedCollection?.shopify?.handle
    )

  const getProductWorld: any = (product: any) =>
    normalisedWorlds?.find((world: any) => world?.tags?.[0] && product?.tags?.includes(world?.tags?.[0]))

  return {
    getCollectionWorld,
    getProductWorld,
  }
}
