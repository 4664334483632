import React from "react"
import { Box } from "@chakra-ui/react"
import Icon from "@app/components/Icon"

type Props = {
  setOpen: () => void
}

const GeolocationPopupClose: React.FC<Props> = ({ setOpen }) => (
  <Box
    position="absolute"
    right={{ base: "20px", md: "40px" }}
    top={{ base: "20px", md: "36px" }}
    zIndex={1000}
    _hover={{ cursor: "pointer" }}
    onClick={setOpen}
  >
    <Icon name="close" height="30px" width="30px" />
  </Box>
)

export default GeolocationPopupClose
