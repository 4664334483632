import React, { useCallback } from "react"
import { IconButton } from "@chakra-ui/react"
import { useConfigContext } from "@app/providers/config"
import Link from "@app/components/Link"
import { useWishlistContext } from "@app/providers/wishlist"
import WishlistIcon from "@app/components/Wishlist/WishlistIcon"
import { useAppContext } from "@app/providers/app"

type Props = {
  color?: string
}

const WishlistWidget: React.FC<Props> = ({ color }) => {
  const { dispatch } = useAppContext()
  const { count } = useWishlistContext()
  const {
    settings: { routes },
  } = useConfigContext()

  const onClick = useCallback(() => {
    dispatch({
      type: "menu",
      payload: false,
    })
    dispatch({
      type: "header",
      payload: null,
    })
  }, [dispatch])

  return (
    <Link to={routes.WISHLIST} onClick={onClick} tabIndex={-1}>
      <IconButton
        variant="headerIcon"
        aria-label="Open wishlist"
        icon={<WishlistIcon color={color} filled={!!count} transition="all .4s ease-in-out" />}
      />
    </Link>
  )
}

export default React.memo(WishlistWidget)
