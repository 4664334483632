type ThemeProps = {
  custom?: {
    hex?: string
  }
  theme: string
}

type SanityColourProps = {
  themeColourText?: ThemeProps
}

export const useSanityColour = (props: SanityColourProps, fallbacks?: any) => {
  const { themeColourText } = props
  const fallback = {
    colour: fallbacks?.colour || (typeof fallbacks === "string" && fallbacks) || "",
  }

  const getThemedColour = (theme: string) => {
    const colour = theme?.toLowerCase()?.replace(/ /g, ".")
    return colour
  }

  const transformColour = (item?: ThemeProps, fallback: string) => {
    if (!item?.theme || (item?.theme === "custom" && !item?.custom?.hex)) return fallback

    const value = item?.theme === "custom" && item?.custom?.hex ? item?.custom?.hex : getThemedColour(item?.theme)

    return value
  }

  const colour = transformColour(themeColourText, fallback?.colour)

  return { colour }
}
