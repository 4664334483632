import React from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { Link as CustomLink, LinkProps } from "@chakra-ui/react"

import { useBreadcrumb } from "@app/hooks/useBreadcrumb"

interface GatsbyProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, '"ref"'> {
  external?: boolean
  state?: any
}

type Props = GatsbyProps & LinkProps

// eslint-disable-next-line
const Link: React.FC<Props> = React.forwardRef(({ children, to = "", external = false, state = {}, ...other }, ref) => {
  const { breadcrumbState } = useBreadcrumb()
  const internal = /^\/(?!\/)/.test(to)
  const customState = breadcrumbState(state)

  return internal && !external ? (
    <CustomLink as={GatsbyLink} to={to} state={customState} {...other}>
      {children}
    </CustomLink>
  ) : (
    <CustomLink href={to} {...(external && { isExternal: true, target: "_blank", rel: "noreferrer" })} {...other}>
      {children}
    </CustomLink>
  )
})

export default React.memo(Link)
