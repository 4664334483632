import React from "react"
import Icon from "@app/components/Icon"
import "../theme/css/logo.css"
import { As, Box, BoxProps, VisuallyHidden } from "@chakra-ui/react"

type Props = {
  color?: string
  width?: any
  animationMode?: "none" | "always" | "hover"
  animationBaseDotColor?: string
  title?: string
  tag?: As
} & BoxProps

const Brand: React.FC<Props> = ({ color, width, animationMode = "none", animationBaseDotColor, title, tag, ...props }) => (
  <Box
    className={`logo ${animationMode === "always" ? "anim-always" : animationMode === "hover" ? "anim-hover" : ""}`}
    display="block"
    {...props}
  >
    <VisuallyHidden as={tag || "span"}>{title || "Allkinds"}</VisuallyHidden>
    <Icon
      name="logo"
      width={width || { base: "109px", sm: "150px" }}
      height="auto"
      title={title || "Allkinds"}
      {...{ color: animationMode !== "hover" && animationBaseDotColor ? animationBaseDotColor : color, fill: color || "black" }}
    />
  </Box>
)

export default React.memo(Brand)
