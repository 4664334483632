// Optimisely: Subscribe via alert bar or side widget - Widget
import React, { useState } from "react"
import { Box, Text } from "@chakra-ui/react"
import Icon from "@app/components/Icon"

type Props = {
  setOpen: (open: boolean) => void
}

export function SubscribeSideWidget({ setOpen }: Props) {
  const [showWidget, setShowWidget] = useState(true)
  return (
    <>
      {showWidget && (
        <Box
          id="optimiselyWidget"
          position="fixed"
          right={0}
          bottom="35%"
          transform="translateX(calc(100% - 18px))"
          zIndex={999}
          display="none"
        >
          <Box
            color="brand.white"
            lineHeight="20px"
            background="brand.darkBlue"
            transform="rotate(-90deg)"
            transformOrigin={0}
            _hover={{ cursor: "pointer" }}
          >
            <Box
              background="brand.white"
              position="absolute"
              borderRadius="50%"
              padding={1}
              right="-15px"
              top="-15px"
              onClick={() => setShowWidget(false)}
            >
              <Icon name="closeSmall" width={5} color="brand.darkBlue" />
            </Box>
            <Text onClick={() => setOpen(true)} paddingY={3} paddingX={4}>
              10% OFF
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}
