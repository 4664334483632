import React, { memo, useCallback, useMemo } from "react"
import { Box, AspectRatio, Image, Text, Flex, Button } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useShopify } from "@app/hooks/useShopify"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"

type Props = {
  product: any
  swiper?: boolean
}

export const GiftWithPurchaseProduct: React.FC<Props> = memo(({ product }) => {
  const { loading } = useCart()
  const { addGwp } = useGiftWithPurchase()
  const { formatMoney } = useShopify()

  const productImage = useMemo(() => product?.images?.[0], [product?.images])
  const defaultVariant = useMemo(() => product?.variants?.[0], [product?.variants])

  const addToCartHandler = useCallback(async () => {
    const variant = defaultVariant
    if (!variant) return
    addGwp(product, defaultVariant)
  }, [addGwp, defaultVariant, product])

  return (
    <Flex role="group" flexDir={"column"} h="100%">
      <Box pos="relative">
        <AspectRatio ratio={1} mb={{ base: "2" }} maxWidth={{ base: "75px", sm: "100px" }} mx="auto">
          <Image src={productImage?.originalSrc} alt={productImage?.altText || ""} loading="lazy" />
        </AspectRatio>
      </Box>

      <Text variant="text15">{product?.title}</Text>

      <Flex justifyContent={{ base: "center", sm: "space-between" }} alignItems={"center"} flexDir={"column"} mt="auto">
        <Button
          onClick={addToCartHandler}
          isLoading={loading}
          variant="fill"
          fontSize="12px"
          width="60px"
          height="26px"
          lineHeight="15px"
          textAlign="center"
          mt={2}
        >
          <Text variant="text15">{loading ? "Adding" : `Add - ${formatMoney(0)}`}</Text>
        </Button>
      </Flex>
    </Flex>
  )
})
