import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useCore } from "@app/hooks/useCore"
import { useCustomerContext } from "@app/providers/customer"
import { useCartContext } from "@app/providers/cart"
import { CartLine } from "@shopify/hydrogen-react/storefront-api-types"
import { NormalisedCart } from "@root/types/cart"

import { useFunctions } from "@app/hooks/useFunctions"

export type ContextProps = {
  emarsysReady: boolean
  trackPage: (collection: any, product: any, overrideCheckout?: any) => void
}

export const EmarsysContext = React.createContext<ContextProps | undefined>(undefined)

export const EmarsysProvider: React.FC = ({ children }) => {
  const {
    helpers: { isDomReady, decodeShopifyId },
  } = useCore()
  const { customer } = useCustomerContext()
  const { cart } = useCartContext()
  const [emarsysLoaded, setEmarsysLoaded] = useState(typeof window !== `undefined` ? !!window.ScarabQueue : false)
  const emarsysReady = useMemo(() => emarsysLoaded && (customer === false || customer) && cart?.id, [cart?.id, customer, emarsysLoaded])
  const { callFunction } = useFunctions()

  const track = (id: string, payload: any = null) => {
    const arr = [id]
    if (payload) {
      arr.push(payload)
    }

    if (window.ScarabQueue) {
      window.ScarabQueue.push(arr)
    }
  }

  const setCartSession = useCallback(
    async (customer: string) => {
      try {
        if (!cart?.id && !cart?.lines && !customer) return
        const cartId = decodeShopifyId(cart?.id, "Cart")

        await callFunction("emarsys-contacts-update", { email: customer, cartId })
      } catch (e) {
        console.error(e)
      }
    },
    [callFunction, cart?.id, cart?.lines, decodeShopifyId]
  )

  // const getCartSession = useMemo(async () => {
  //   if (loading) return
  //   const emarsysCartId = getUrlParameter(params.cart)
  //   const shopifyCartId = decodeShopifyId(getStorage(keys.cart), "Cart")

  //   if (!emarsysCartId || emarsysCartId === shopifyCartId) return
  //   setStorage(keys.cart, `gid://shopify/Cart/${emarsysCartId}`, expiryTimeInDays || 0)
  //   createCart(countryCode)
  // }, [countryCode, createCart, decodeShopifyId, expiryTimeInDays, getStorage, getUrlParameter, keys.cart, loading, params.cart, setStorage])

  const identify = useCallback(
    (customer: any) => {
      if (customer?.email) {
        track("setEmail", `${customer?.email}`)
        setCartSession(customer?.email)
      }
    },
    [setCartSession]
  )

  const trackCart = useCallback(
    (lines: CartLine[]) => {
      try {
        if (!lines) {
          lines = []
        }

        track(
          "cart",
          lines.map(item => ({
            item: decodeShopifyId(item?.merchandise?.id, "ProductVariant"),
            price: parseFloat(item.cost.subtotalAmount.amount),
            quantity: item.quantity,
          }))
        )
      } catch (e) {
        console.error(e)
      }
    },
    [decodeShopifyId]
  )

  const trackProductView = useCallback((productId: string) => {
    try {
      track("view", `g/${productId}`)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const trackCategory = useCallback(collection => {
    track("category", collection?.title)
  }, [])

  const trackGo = useCallback(() => {
    track("go")
  }, [])

  const trackPage = useCallback(
    (collection = null, product = null, overrideCart?: NormalisedCart) => {
      if (customer) {
        identify(customer)
      }
      trackCart(overrideCart?.lines || cart?.lines || [])
      if (product?.shopify?.id) {
        trackProductView(product?.shopify?.id)
      }
      if (collection) {
        trackCategory(collection)
      }
      trackGo()
    },
    [cart?.lines, customer, identify, trackCart, trackCategory, trackGo, trackProductView]
  )

  useEffect(() => {
    if (emarsysLoaded) {
      return
    }

    // Poll until Emarsys is loaded via GTM
    const interval = setInterval(() => {
      if (window.ScarabQueue) {
        setEmarsysLoaded(true)
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = React.useMemo<ContextProps>(() => ({ emarsysReady, trackPage }), [emarsysReady, trackPage])

  return isDomReady ? <EmarsysContext.Provider value={contextValue}>{children}</EmarsysContext.Provider> : <>{children}</>
}

export const useEmarsysContext = (): ContextProps => ({ ...React.useContext(EmarsysContext) } as ContextProps)
