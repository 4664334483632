import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useQuery } from "@apollo/client"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

type ContextProps = {
  customer: any
  getCustomer: () => void | any
  setCustomer: (customer: any) => void
  clearCustomer: () => void
  saveCustomer: (customerAccessToken: any) => void
}

export const CustomerContext = React.createContext<ContextProps | undefined>(undefined)

export const CustomerProvider: React.FC = ({ children }) => {
  const { store } = useConfigContext()
  const {
    graphql: {
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()

  // Object: authed
  // false: unauthed
  // null: state not yet fetched / unknown
  const [customer, setCustomer] = useState<any>(null)

  const { refetch: getCustomerQuery } = useQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    skip: true,
    variables: {
      countryCode: store?.locationRegion,
    },
  })

  const token = useMemo(() => getStorage(keys.customer), [getStorage, keys])

  useEffect(() => {
    getCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCustomer = useCallback(
    async (accessToken = null) => {
      if (!accessToken) {
        accessToken = token?.accessToken
      }

      if (accessToken) {
        try {
          const {
            data: { customer, customerUserErrors },
          } = await getCustomerQuery({
            customerAccessToken: accessToken,
          })

          if (customerUserErrors?.length) {
            removeStorage(keys.customer)
            removeStorage(keys.customerOther)
            setCustomer(false)
          } else {
            setCustomer(customer)
            return customer
          }
        } catch (e) {
          console.error((e as Error).message)
          setCustomer(false)
        }
      } else {
        setCustomer(false)
      }
    },
    [getCustomerQuery, setCustomer, removeStorage, keys, token]
  )

  const saveCustomer = useCallback(
    customerAccessToken => {
      try {
        const { accessToken, expiresAt } = customerAccessToken
        setStorage(keys.customer, { accessToken, expiresAt })

        // Pass access token since token.accessToken has not yet updated
        // and getCustomer would use the incorrect token otherwise
        getCustomer(accessToken)
        // updateCustomer(accessToken)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [getCustomer, setStorage, keys]
  )

  const clearCustomer = useCallback(() => {
    try {
      removeStorage(keys.customer)
      removeStorage(keys.customerOther)
      setCustomer(false)
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [setCustomer, removeStorage, keys])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      customer,
      getCustomer,
      setCustomer,
      saveCustomer,
      clearCustomer,
    }),
    [customer, getCustomer, saveCustomer, clearCustomer]
  )

  return <CustomerContext.Provider value={contextValue}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ContextProps => ({ ...React.useContext(CustomerContext) } as ContextProps)
