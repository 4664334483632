import React, { useCallback, useEffect, useState } from "react"
import { Box, Button } from "@chakra-ui/react"
import FocusTrap from "focus-trap-react"

import { useAppContext } from "@app/providers/app"
import NavigationHeaderMegaLevel1 from "@app/components/Navigation/Header/NavigationHeaderMegaLevel1"
import NavigationHeaderMegaLevel2 from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2"

type Props = {
  item: GatsbyTypes.SanityHeaderMega
  headerOffset: { height: any; top: number }
}

const NavigationHeaderMega: React.FC<Props> = ({ item, headerOffset }) => {
  const { state, dispatch, activeHeaderMegaItem, setActiveHeaderMegaItem } = useAppContext()

  const [activeHeaderMegaIndex, setActiveHeaderMegaIndex] = useState<number | null>(null)
  const [mouseExit, setMouseExit] = useState(true)
  const [trapped, setTrapped] = useState(false)

  const visible = item?._key === state?.activeHeader?._key

  const handleItemClick = useCallback(
    (item: any, index: number) => {
      setActiveHeaderMegaItem(item)
      setActiveHeaderMegaIndex(index)
      setTrapped(true)
    },
    [setActiveHeaderMegaItem, setActiveHeaderMegaIndex, setTrapped]
  )

  useEffect(() => {
    if (visible) {
      for (const i in item?.items) {
        if (item?.items?.[i]._type !== "newHeaderMegaLink") {
          setActiveHeaderMegaItem(item?.items?.[i])
          setActiveHeaderMegaIndex(parseInt(i))
          break
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onToggle = useCallback(
    (payload: any) => {
      dispatch({
        type: "header",
        payload: payload,
      })
      if (!payload) {
        for (const i in item?.items) {
          if (item?.items?.[i]._type !== "newHeaderMegaLink") {
            setActiveHeaderMegaItem(item?.items?.[i])
            setActiveHeaderMegaIndex(parseInt(i))
            break
          }
        }
      }
    },
    [dispatch, item?.items, setActiveHeaderMegaItem]
  )

  // Nav close logic
  const handleMouseLeave = useCallback(() => {
    setMouseExit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeactivateTrap = useCallback(() => {
    if (!state?.activeHeader?._key && !mouseExit) {
      onToggle(null)
    }
  }, [mouseExit, onToggle, state?.activeHeader?._key])

  handleDeactivateTrap
  return (
    <FocusTrap
      active={visible && !mouseExit}
      focusTrapOptions={{
        initialFocus: false,
      }}
    >
      <Box onMouseLeave={handleMouseLeave}>
        <Button
          variant="headerItem"
          onClick={e => {
            e.stopPropagation()
            setMouseExit(false)
            setActiveHeaderMegaItem(item)
            onToggle(visible ? null : item)
          }}
          bgColor={visible ? activeHeaderMegaItem?.primaryColor?.preset || "preset.default" : "transparent"}
          color={visible ? "brand.white" : "auto"}
          transition="background-color 0.1s ease-in-out"
        >
          {item?.title}
        </Button>
        <Box
          pointerEvents={visible ? "all" : "none"}
          pos="absolute"
          h={`calc(100vh - ${headerOffset.height}px)`}
          top={`${headerOffset.top}px`}
          zIndex={55}
          bottom={0}
          left={0}
          right={0}
          opacity={visible ? 1 : 0}
          overflow={`hidden`}
        >
          <NavigationHeaderMegaLevel1
            item={item}
            selectedItem={activeHeaderMegaItem}
            activeHeaderIndex={activeHeaderMegaIndex}
            visible={visible}
            handleItemClick={handleItemClick}
            handleLinkClick={() => {
              onToggle(null)
            }}
          />

          {activeHeaderMegaItem?._type !== "newHeaderMegaLink" && (
            <NavigationHeaderMegaLevel2
              item={visible && activeHeaderMegaItem}
              trapped={trapped}
              handleOutsideClick={() => {
                onToggle(null)
              }}
              handleLinkClick={() => {
                onToggle(null)
              }}
              maxHeight={`calc(100vh - ${headerOffset.height}px)`}
              handleClose={() => {
                setTrapped(false)
              }}
            />
          )}
        </Box>
      </Box>
    </FocusTrap>
  )
}

export default React.memo(NavigationHeaderMega)
