export const styles = {
  global: {
    html: {
      WebkitFontSmoothing: "auto",
    },
    body: {
      fontFamily: "Larsseit",
      fontFeatureSettings: '"ss01" on, "ss02" on',
      bg: "brand.white",
      color: "brand.darkBlue",
    },
    a: {
      color: "brand.black",
      textDecoration: "underline",
    },

    "h1, h2, h3, h4, strong": {
      fontFamily: "Larsseit",
      fontFeatureSettings: '"ss01" on, "ss02" on',
    },
    "h1, .h1": {
      fontSize: { base: 40, md: 50 },
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: { base: 30, md: 32 },
      lineHeight: { base: 1.3, md: 1.4 },
    },
    "h3, .h3": {
      fontSize: { base: 20, md: 26 },
      lineHeight: { base: 1.3, md: "34px" },
    },
    p: {
      fontFamily: "Graphik, sans-serif",
      fontFeatureSettings: '"ss01" on, "ss02" on',
      fontSize: { base: 18, sm: 20 },
      lineHeight: { base: 1.4, sm: 1.5 },
    },
    strong: {
      fontWeight: 500,
    },

    ".small": {
      fontSize: "16px",
      lineHeight: { base: 1.3, md: 1.5 },
      my: "14px",
    },
    ".subtext": {
      fontSize: "16px",
      lineHeight: { base: 1.3, md: "34px" },
      my: "14px",
    },

    ".content-h3": {
      fontSize: { base: 20, sm: 30 },
      lineHeight: { base: 1.3, sm: "42px" },
    },
    ".content-p": {
      my: 3,
    },
    ".content-list": {
      mt: "16px",
      ml: "40px",
      mb: "24px",
      p: {
        my: 0,
        pl: "8px",
      },
      ol: {
        li: {
          mb: "16px",
        },
      },
    },

    ".react-video video": {
      objectFit: "cover",
    },

    ".user-icon svg": {
      w: "auto",
      h: { base: "30px", sm: "36px" },
    },

    ".squiggle-underline": {
      pos: "relative",
      _after: {
        content: '""',
        display: "block",
        bg: "url(/images/underline.svg) repeat-x bottom/8px auto",
        w: "100%",
        h: 2,
        pos: "absolute",
        left: 0,
        top: "100%",
      },
    },
  },
}
