import React from "react"
import { AppProvider } from "@app/providers/app"
import { ThemeProvider } from "@app/providers/theme"
import { ConfigProvider } from "@app/providers/config"
import { ShopifyProvider } from "@app/providers/shopify"
import { LocationProvider } from "@app/providers/location"
import { TrackingProvider } from "@app/providers/tracking"
import { CustomerProvider } from "@app/providers/customer"
import { CartProvider } from "@app/providers/cart"
import { WishlistProvider } from "@app/providers/wishlist"
import { EmarsysProvider } from "@app/providers/emarsys"
import { GlobalProvider } from "./global"

const Providers: React.FC = ({ children }) => (
  <LocationProvider>
    <ConfigProvider>
      <ShopifyProvider>
        <AppProvider>
          <GlobalProvider>
            <CustomerProvider>
              <CartProvider>
                <WishlistProvider>
                  <TrackingProvider>
                    <EmarsysProvider>
                      <ThemeProvider>{children}</ThemeProvider>
                    </EmarsysProvider>
                  </TrackingProvider>
                </WishlistProvider>
              </CartProvider>
            </CustomerProvider>
          </GlobalProvider>
        </AppProvider>
      </ShopifyProvider>
    </ConfigProvider>
  </LocationProvider>
)

export default Providers
