import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"

const Main = ({ children, ...props }: { children: any } & BoxProps) => (
  <Box as="main" {...props}>
    {children}
  </Box>
)

export default React.memo(Main)
