import React, { useState, useEffect, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"
import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module"

import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useCartContext } from "@app/providers/cart"

export type ProductImpressionProps = {
  item_id: string
  item_name: string
  item_brand: string
  item_variant: string
  item_category: string
  item_list_name: string
  item_list_id: string
  index: number
  quantity: number | string
  price: number | string
}

export type ContextProps = {
  tracked: boolean
  setTracked: React.Dispatch<React.SetStateAction<boolean>>
  latestVersion: boolean
  colourOptionName: string
  gtm: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
  setProductImpressionBatch: React.Dispatch<React.SetStateAction<Array<ProductImpressionProps>>>
  productImpressionBatch: Array<ProductImpressionProps>
  pushDataLayer: (datalayer: any) => void
}

export const TrackingContext = React.createContext<ContextProps | undefined>(undefined)

export const TrackingProvider: React.FC = ({ children }) => {
  const {
    settings: {
      product: { colourOptionName },
    },
  } = useConfigContext()
  const {
    helpers: { isDomReady, isDev },
  } = useCore()
  const { currencyCode } = useCartContext()
  const [tracked, setTracked] = useState(false)
  const [productImpressionBatch, setProductImpressionBatch] = useState<Array<ProductImpressionProps>>([])

  const { tracking } = useStaticQuery<GatsbyTypes.StaticTrackingQuery>(graphql`
    query StaticTracking {
      tracking: sanitySettingTracking {
        facebookAppId
        googleSiteVerify
        googleTagManagerId
      }
    }
  `)

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (TagManager) {
        isDev ? console.log({ ...dataLayer }) : TagManager.dataLayer({ dataLayer })
      }
    },
    [isDev]
  )

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      tracked,
      setTracked,
      productImpressionBatch,
      setProductImpressionBatch,
      latestVersion: true, //"GA4"
      colourOptionName, //"Colour"
      gtm: TagManager,
      pushDataLayer,
    }),
    [tracked, setTracked, colourOptionName, productImpressionBatch, pushDataLayer, setProductImpressionBatch]
  )

  useEffect(() => {
    if (tracking?.googleTagManagerId) {
      TagManager.initialize({
        gtmId: tracking?.googleTagManagerId,
      })
      setTracked(true)
    }
  }, [tracking])

  useEffect(() => {
    if (productImpressionBatch.length <= 16 && productImpressionBatch.length > 11) {
      pushDataLayer({
        event: "view_item_list",
        ecommerce: {
          currency: currencyCode,
          items: productImpressionBatch,
        },
      })
      setProductImpressionBatch([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productImpressionBatch.length, currencyCode, pushDataLayer])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH" && productImpressionBatch.length) {
        pushDataLayer({
          event: "view_item_list",
          ecommerce: {
            currency: currencyCode,
            items: productImpressionBatch,
          },
        })
        setProductImpressionBatch([])
      }
    })
  }, [currencyCode, productImpressionBatch, pushDataLayer])

  return isDomReady ? <TrackingContext.Provider value={contextValue}>{children}</TrackingContext.Provider> : <>{children}</>
}

export const useTrackingContext = (): ContextProps => ({ ...React.useContext(TrackingContext) } as ContextProps)
