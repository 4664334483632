import { Radio as BaseRadio } from "@chakra-ui/react"

export const Radio = {
  ...BaseRadio.defaultProps,
  baseStyle: {
    ...BaseRadio.baseStyle,
    control: {
      ...BaseRadio.baseStyle?.control,
      bg: "none",
      borderColor: "brand.darkBlue",
      _before: {
        height: "70% !important",
        width: "70% !important",
      },
      _checked: {
        bg: "white",
        borderColor: "brand.darkBlue",
        color: "black",
      },
    },
  },
}
