import React, { useCallback, useState } from "react"
import { Box, Button } from "@chakra-ui/react"
import FocusTrap from "focus-trap-react"

import { useAppContext } from "@app/providers/app"
import NavigationHeaderMegaLevel2 from "@app/components/Navigation/Header/NavigationHeaderMegaLevel2"

type Props = {
  item: GatsbyTypes.SanityNewHeaderDropdown
  onHeaderToggle: (payload: any) => void
  headerOffset: { height: any; top: number }
}

const NavigationHeaderDropdown: React.FC<Props> = ({ item, onHeaderToggle, headerOffset }) => {
  const { state } = useAppContext()

  const [mouseExit, setMouseExit] = useState(true)
  const [trapped, setTrapped] = useState(false)

  const dropDownItem = item?.item?.[0]
  const visible = item?._key === state?.activeHeader?._key

  // Nav close delay logic
  const handleMouseLeave = useCallback(() => {
    setMouseExit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeactivateTrap = useCallback(() => {
    if (!state?.activeHeader?._key && !mouseExit) {
      onHeaderToggle(null)
    }
  }, [mouseExit, onHeaderToggle, state?.activeHeader?._key])

  return (
    <FocusTrap
      active={visible && !mouseExit}
      focusTrapOptions={{
        onDeactivate: handleDeactivateTrap,
        initialFocus: false,
      }}
    >
      <Box onMouseLeave={handleMouseLeave}>
        <Button
          variant="headerItem"
          onClick={e => {
            e.stopPropagation()
            setMouseExit(false)
            onHeaderToggle(visible ? null : item)
          }}
          bgColor={visible ? dropDownItem?.primaryColor?.preset || "preset.default" : "transparent"}
          color={visible ? "brand.white" : "auto"}
          transition="background-color 0.3s ease-in-out"
        >
          {item?.title}
        </Button>
        <Box
          pointerEvents={visible ? "all" : "none"}
          pos="absolute"
          h={`calc(100vh - ${headerOffset.height}px)`}
          top={`${headerOffset.top}px`}
          zIndex={55}
          bottom={0}
          left={0}
          right={0}
          opacity={visible ? 1 : 0}
          overflow={`hidden`}
        >
          <NavigationHeaderMegaLevel2
            item={visible && dropDownItem}
            mega={false}
            trapped={trapped}
            handleOutsideClick={() => {
              onHeaderToggle(null)
            }}
            handleLinkClick={() => {
              onHeaderToggle(null)
            }}
            maxHeight={`calc(100vh - ${headerOffset.height}px)`}
            handleClose={() => {
              setTrapped(false)
            }}
          />
        </Box>
      </Box>
    </FocusTrap>
  )
}

export default React.memo(NavigationHeaderDropdown)
