import React, { useMemo } from "react"
import { Box } from "@chakra-ui/react"

import { withLayout } from "@app/hoc/Layout"
import { useConfigContext } from "@app/providers/config"
import Global from "@app/theme/foundations/global"
import Meta from "@app/components/Meta"
import Main from "@app/components/Main"
import Header from "@app/components/Header"
import { CartMini } from "@app/components/Cart/CartMini"
import TvSplash from "@app/components/Tv/TvSplash"
import MotionBox from "@app/components/MotionBox"
import GeolocationPopup from "@app/components/Geolocation/GeolocationPopup"
import Subscribe from "@app/components/Subscribe/Subscribe"

import type { PageProps } from "@root/types/global"
import { SubscribeSideWidget } from "./Subscribe/SubscribeSideWidget"
import { useNewsletter } from "@app/hooks/useNewsletter"

const Footer = React.lazy(() => import("@app/components/Navigation/Footer/Footer"))

const Layout: React.FC<PageProps<any>> = ({ children, data, location }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { handleOpen } = useNewsletter(location)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isTv = useMemo(() => data?.page?.url === routes.TV || data?.tvShow, [data?.page?.url])
  const isCart = useMemo(() => location?.pathname?.includes(routes.CART), [location?.pathname, routes.CART])
  const isDisableTransition = useMemo(
    () => (location.state && location.state.disableTransition) || data?.page?.url?.startsWith(routes.DASHBOARD) || true,
    [data?.page?.url, location.state, routes.DASHBOARD]
  )

  return (
    <Box>
      <Global />
      <Meta data={data} location={location} />
      {!isTv && <Header isHomePage={location?.pathname === "/"} location={location} />}
      <Main id="main">
        {isDisableTransition ? (
          children
        ) : (
          <MotionBox
            initial={{ opacity: 0, top: -200 }}
            animate={{ opacity: 1, top: 0 }}
            exit={{ opacity: 0, top: -200 }}
            key={location.pathname}
            transition={{
              duration: "1",
            }}
          >
            {children}
          </MotionBox>
        )}
      </Main>
      {!isCart && <CartMini />}
      <GeolocationPopup />
      <Subscribe location={location} />
      <SubscribeSideWidget setOpen={handleOpen} />
      {!isTv && (
        <React.Suspense fallback={<div />}>
          <Footer />
        </React.Suspense>
      )}
      {isTv && <TvSplash />}
    </Box>
  )
}

export default withLayout(Layout)
