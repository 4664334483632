export const Accordion = {
  variants: {
    pdp: {
      container: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        justifyContent: "center",
        mb: "16px",
      },
      button: {
        display: "flex",
        gap: "16px",
        color: "primary.navy",
        fontSize: "20px",
        lineHeight: 1.16,
        px: "20px",
        py: "18px",
        borderRadius: "32px",
        backgroundColor: "primary.white",
        _hover: {
          backgroundColor: "primary.purple",
        },
        _selected: {
          backgroundColor: "primary.purple",
        },
        _active: {
          backgroundColor: "primary.purple",
        },
      },
      panel: {
        backgroundColor: "primary.white",
        p: 0,
      },
    },
  },
  defaultProps: {
    size: "unset",
    variant: "unset",
  },
}
