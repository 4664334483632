import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useConfigContext } from "@app/providers/config"
import axios from "axios"
import { Customer } from "@shopify/hydrogen-react/storefront-api-types"

import { useCore, useStorage } from "@app/hooks/useCore"
import { Wishlist, WishlistItem, WishlistShareColumns } from "@root/types/wishlist"
import { useCustomerContext } from "@app/providers/customer"
import { useFunctions } from "@app/hooks/useFunctions"
import { navigate } from "gatsby"

export const DEFAULT_WISHLIST_NAME = "DEFAULT_WISHLIST"

const generateUUIDv4 = () => {
  const bytes = new Uint8Array(16)
  crypto.getRandomValues(bytes)

  // Set the version to 4 (randomly generated UUID)
  bytes[6] = (bytes[6] & 0x0f) | 0x40
  // Set the variant to DCE 1.1 (binary 10)
  bytes[8] = (bytes[8] & 0x3f) | 0x80

  const hex = Array.from(bytes, byte => byte.toString(16).padStart(2, "0")).join("")
  const uuid = `${hex.substring(0, 8)}-${hex.substring(8, 12)}-${hex.substring(12, 16)}-${hex.substring(16, 20)}-${hex.substring(20, 32)}`
  return uuid
}

const createNewListItem = (existingItem: WishlistItem) => ({
  du: existingItem?.du,
  dt: existingItem?.dt,
  iu: existingItem?.iu,
  empi: existingItem?.empi,
  epi: existingItem?.epi,
  ct: "",
  _cv: true,
  vi: "",
  pr: existingItem?.pr,
})

export type PostOptions = {
  version?: number
}

export type ContextProps = {
  fetched: boolean
  wishlists: Wishlist[]
  sharedWishlist: Wishlist | null
  count: number
  isSyncing: boolean
  tryCreateWishlist: (name?: string) => void
  isInWishlist: (product: any) => boolean
  addToWishlist: (product: any, wishlistId?: string) => Promise<boolean>
  moveFromWishlist: (product: any, sourceId: string, destinationId: string) => Promise<boolean>
  removeFromWishlist: (product: any) => Promise<boolean>
  renameWishlist: (wishlistId: string, name: string) => void
  deleteWishlist: (wishlistId: string) => void
  shareWishlist: (wishlistId: string) => void
  getShareUrl: (wishlistId: string) => string
  emailSharedWishlist: (email: string, wishlistId: string) => void
}

export const WishlistContext = React.createContext<ContextProps | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const WishlistProvider: React.FC<Props> = ({ children }) => {
  const {
    store,
    settings: { keys, routes },
  } = useConfigContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { customer } = useCustomerContext()
  const { callFunction } = useFunctions()

  const { getStorage, setStorage, removeStorage } = useStorage()
  const [fetched, setFetched] = useState<boolean>(false)
  const [wishlists, setWishlists] = useState<Wishlist[]>([])
  const [sharedWishlist, setSharedWishlist] = useState<Wishlist | null>(null)
  const [wasLoggedIn, setWasLoggedIn] = useState<boolean>(false)
  const [isSyncing, setIsSyncing] = useState<boolean>(false)

  const generateSessionId = useCallback(() => {
    const cachedSessionId = getStorage(keys.wishlistSessionId)
    if (cachedSessionId) return cachedSessionId
    const a = 64
    let b
    let c
    for (b = ""; b.length < a; ) {
      c = Math.random().toString(36).slice(2)
      b += c.slice(0, Math.min(c.length, a - b.length))
    }
    return b.toLowerCase()
  }, [getStorage, keys.wishlistSessionId])

  const setUserId = useCallback(
    (userId: string) => {
      setStorage(keys.wishlistRegId, userId)
    },
    [keys.wishlistRegId, setStorage]
  )

  const getGuestId = useCallback(async () => {
    if (!store?.wishlistPlusStorefrontEndpoint || !store?.wishlistPlusPid) {
      return null
    }

    try {
      const id = getStorage(keys.wishlistRegId)

      if (id?.length) return id

      const response = await callFunction("wishlist-admin", {
        action: "generate-regid",
        formData: {
          // Generate a unique id that conforms to the UUIDv4 standard to register a guest user
          uuid: generateUUIDv4(),
          useragenttype: "Allkinds",
        },
      })

      const newRegId = response?.regid
      const newSessionId = response?.sessionid

      if (newRegId) {
        setUserId(newRegId)
        setStorage(keys.wishlistSessionId, newSessionId)

        return newRegId
      }
    } catch (e) {
      console.error(e)
    }

    return null
  }, [
    store?.wishlistPlusStorefrontEndpoint,
    store?.wishlistPlusPid,
    getStorage,
    keys.wishlistRegId,
    keys.wishlistSessionId,
    callFunction,
    setUserId,
    setStorage,
  ])

  const post = useCallback(
    async (path: string, data = {}, options: PostOptions = {}) => {
      if (!store?.wishlistPlusStorefrontEndpoint || !store?.wishlistPlusPid) {
        return null
      }

      try {
        return await axios.post(
          `${store.wishlistPlusStorefrontEndpoint}v${options.version || 3}/${path}?pid=${store.wishlistPlusPid}`,
          new URLSearchParams({
            regid: await getGuestId(),
            sessionid: generateSessionId(),
            ...data,
          })
        )
      } catch (e) {
        return e
      }
    },
    [generateSessionId, getGuestId, store.wishlistPlusPid, store.wishlistPlusStorefrontEndpoint]
  )

  const count = useMemo(() => {
    return wishlists?.reduce((sum: number, list) => sum + (list?.listcontents?.length ? list?.listcontents?.length : 0), 0)
  }, [wishlists])

  const fetchWishlists = useCallback(
    async (force = false, checkValidity = true) => {
      if (wishlists?.length && !force) return wishlists
      let fetchedLists: Wishlist[] = []

      const fetchedData = await post("lists/fetch-lists")
      fetchedLists = fetchedData?.data as Wishlist[]

      // Fail safe to handle invalid regid
      if (fetchedData?.response?.data?.type === "sw-badregid") {
        removeStorage(keys.wishlistRegId)
        removeStorage(keys.wishlistSessionId)
        removeStorage(keys.wishlistSynced)
        return await fetchWishlists(true)
      }

      const sortedLists = fetchedLists?.sort((a, b) => a.cts - b.cts)

      const defaultLists = sortedLists?.filter(list => list?.lname === DEFAULT_WISHLIST_NAME)
      const defaultList = defaultLists?.[0]

      if (checkValidity) {
        if (sortedLists?.length > 0 && !defaultList && sortedLists?.[0]?.lid) {
          // If there are lists but no default list, create one
          // This logic is added to handle LEGACY cases where there are multiple wishlists but no default one
          await post("lists/update", {
            lid: sortedLists[0].lid,
            lname: DEFAULT_WISHLIST_NAME,
          })
          return await fetchWishlists(true, false)
        } else if (defaultLists?.length > 1) {
          // If there are multiple default lists, merge them into one
          // In certain cases, there might be multiple default lists due to legacy issues
          const productsToMigrate = defaultLists
            ?.slice(1)
            .flatMap(list => list?.listcontents)
            ?.map(createNewListItem)

          const wishlistsToDelete = defaultLists?.slice(1).map(list => list?.lid)

          await post("lists/update-ctx", {
            lid: defaultList?.lid,
            a: JSON.stringify(productsToMigrate),
          })

          for (const lid of wishlistsToDelete) {
            await post("lists/delete-list", {
              lid,
            })
          }
          return await fetchWishlists(true, false)
        }
      }

      const sortedListsWithDefault = defaultList
        ? [defaultList, ...sortedLists.filter(list => list?.lname !== DEFAULT_WISHLIST_NAME)]
        : sortedLists

      return (
        sortedListsWithDefault?.map((wishlist: any, index: number) => ({
          ...wishlist,
          isDefault: index === 0,
        })) || []
      )
    },
    [post, removeStorage, wishlists, keys.wishlistRegId, keys.wishlistSessionId, keys.wishlistSynced]
  )

  const fetchSharedWishlist = async (wishlistId: string) => {
    return {
      ...(
        await post("lists/fetch-list-with-contents", {
          lid: wishlistId,
        })
      )?.data?.list,
      shared: true,
    } as Wishlist
  }

  const refreshWishlists = useCallback(async () => {
    setWishlists(await fetchWishlists(true))
    if (!fetched) {
      setFetched(true)
    }
  }, [fetchWishlists, fetched])

  const renameWishlist = useCallback(
    async (wishlistId: string, name: string) => {
      await post("lists/update", {
        lid: wishlistId,
        lname: name,
      })
      await refreshWishlists()
    },
    [post, refreshWishlists]
  )

  const tryCreateWishlist = useCallback(
    async (name?: string) => {
      const lists = await fetchWishlists()

      const lastUnnamedWishlistNumber = lists
        ?.map(list => {
          return list?.lname?.includes("Wishlist-") ? parseInt(list?.lname?.split("-")?.[1]) || 0 : 0
        })
        .reduce((max: number, cur: number) => {
          return Math.max(max, cur)
        }, 0)

      await post("lists/create-multiple", {
        lists: JSON.stringify([
          {
            lname: name ? name : `Wishlist-${lastUnnamedWishlistNumber + 1}`,
          },
        ]),
      })

      await refreshWishlists()
    },
    [fetchWishlists, post, refreshWishlists]
  )

  const tryCreateDefaultList = useCallback(async () => {
    const list = await fetchWishlists()
    if (list && list.length > 0) {
      return list[0].lid
    }

    return (
      await post("lists/create-multiple", {
        lists: JSON.stringify([{ lname: DEFAULT_WISHLIST_NAME }]),
      })
    )?.data?.[0]?.lid
  }, [fetchWishlists, post])

  const isInWishlist = useCallback(
    (product: any) => {
      const productId = decodeShopifyId(product?.storefrontId || product?.id, "Product")
      if (!productId) {
        return false
      }
      return wishlists?.findIndex(list => !!list?.listcontents?.find(item => item.empi === parseInt(productId))) !== -1
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wishlists]
  )

  const addToWishlist = useCallback(
    async (product: any, wishlistId: string | null = null) => {
      const productId = decodeShopifyId(product?.storefrontId || product?.id, "Product")
      if (!productId) {
        return false
      }
      const variantId = decodeShopifyId(product?.variants?.[0]?.storefrontId || product?.variants?.[0]?.id, "ProductVariant")
      if (!variantId) {
        return false
      }

      const listId = wishlistId ? wishlistId : await tryCreateDefaultList()

      if (listId === null) {
        return false
      }

      let price = null
      try {
        price = parseFloat(product?.variants?.[0].priceV2?.amount)
      } catch (e) {
        // ignored
      }

      await post("lists/update-ctx", {
        lid: listId,
        a: JSON.stringify([
          {
            du: `https://${store.url}/products/${product.handle}`,
            dt: product.title,
            iu: product?.featuredImage?.originalSrc,
            empi: productId,
            epi: variantId,
            ct: "",
            _cv: true,
            vi: "",
            pr: price,
          },
        ]),
      })

      await refreshWishlists()
      return true
    },
    [decodeShopifyId, post, refreshWishlists, store.url, tryCreateDefaultList]
  )

  const removeFromWishlist = useCallback(
    async (product: any, targetWishlistId?: string) => {
      const wishlists = await fetchWishlists()
      const productId = parseInt(decodeShopifyId(product?.storefrontId || product?.id, "Product"))
      if (!productId) {
        return false
      }

      const lists = targetWishlistId
        ? [wishlists?.find(list => list?.lid === targetWishlistId)]
        : wishlists?.filter(list => list?.listcontents?.findIndex((item: any) => item.empi === productId) !== -1)

      if (!lists?.length) return false

      const variantId = lists?.[0]?.listcontents?.find(item => item.empi === productId)?.epi
      if (!variantId) return false

      // Optimistic update
      setWishlists(
        wishlists?.map(wishlist => {
          if (!!targetWishlistId && wishlist?.lid !== targetWishlistId) return wishlist
          const listcontents = wishlist?.listcontents?.filter((item: any) => item.empi !== productId)
          return {
            ...wishlist,
            listcontents,
          }
        })
      )

      try {
        for (const list of lists) {
          await post("lists/update-ctx", {
            lid: list?.lid,
            d: JSON.stringify([
              {
                du: `https://${store.url}/products/${product.handle}`,
                empi: productId,
                epi: variantId,
              },
            ]),
          })
        }
      } catch {
        console.error("Failed to remove item from wishlist")
        await refreshWishlists()
      }

      return true
    },
    [decodeShopifyId, fetchWishlists, post, refreshWishlists, store.url]
  )

  const moveFromWishlist = useCallback(
    async (product: any, sourceId: string, destinationId: string) => {
      const sourceList = wishlists?.find(list => list?.lid === sourceId)
      const destinationList = wishlists?.find(list => list?.lid === destinationId)

      if (!sourceList || !destinationList) {
        return false
      }

      const productId = parseInt(decodeShopifyId(product?.storefrontId || product?.id, "Product"))
      if (!productId) {
        return false
      }

      const existingItem = sourceList?.listcontents?.find(item => item.empi === productId)
      if (!existingItem) {
        return false
      }

      // Check if the product already exists in the destination list, if so, do not move it
      if (destinationList?.listcontents?.findIndex(item => item.empi === productId) !== -1) {
        return false
      }

      // Optimistic update
      setWishlists(
        wishlists?.map(wishlist => {
          const isSource = wishlist?.lid === sourceId
          const isDestination = wishlist?.lid === destinationId

          const listcontents = wishlist?.listcontents?.filter(item => item.empi !== productId)

          if (isSource) {
            return {
              ...wishlist,
              listcontents,
            }
          } else if (isDestination) {
            return {
              ...wishlist,
              listcontents: [existingItem, ...listcontents],
            }
          } else {
            return wishlist
          }
        })
      )

      try {
        await post("lists/update-ctx", {
          lid: sourceId,
          d: JSON.stringify([createNewListItem(existingItem)]),
        })
        await post("lists/update-ctx", {
          lid: destinationId,
          a: JSON.stringify([createNewListItem(existingItem)]),
        })
      } catch {
        console.error("Failed to move item from wishlist")
        await refreshWishlists()
      }

      return true
    },
    [decodeShopifyId, post, refreshWishlists, wishlists]
  )

  const deleteWishlist = useCallback(
    async (wishlistId: string) => {
      await post("lists/delete-list", {
        lid: wishlistId,
      })
      await refreshWishlists()
    },
    [post, refreshWishlists]
  )

  const shareWishlist = useCallback(
    async (wishlistId: string) => {
      await post("lists/markPublic", {
        lid: wishlistId,
      })
    },
    [post]
  )

  const getShareUrl = useCallback(
    (wishlistId: string) => {
      return `https://${location.host}${store.subfolder}${routes.WISHLIST_SHARED}/?sharedlist=${wishlistId}`
    },
    [routes.WISHLIST_SHARED, store.subfolder]
  )

  const emailSharedWishlist = useCallback(
    async (
      { senderName, recipientEmail, note }: { senderName: string; recipientEmail: string; note?: string },
      wishlistId: string,
      wishlistProducts: any[]
    ) => {
      const wishlists = await fetchWishlists()
      const sharedListName = wishlists?.find(list => list?.lid === wishlistId)?.lname

      callFunction("google-sheets-update", {
        row: {
          [WishlistShareColumns.CUSTOMER_EMAIL]: customer?.email,
          [WishlistShareColumns.CUSTOMER_ID]: decodeShopifyId(customer?.id, "Customer"),
          [WishlistShareColumns.CUSTOMER_NAME]: senderName,
          [WishlistShareColumns.RECIPIENT_EMAIL]: recipientEmail,
          [WishlistShareColumns.PRODUCT_CODES]: wishlistProducts
            ?.map(product => product?.tags?.find((tag: string) => tag?.startsWith("colourCode:"))?.split(":")?.[1])
            .join(","),
          [WishlistShareColumns.PRODUCT_HANDLES]: wishlistProducts?.map(product => product?.handle).join(","),
          [WishlistShareColumns.PRODUCT_IDS]: wishlistProducts
            ?.map(product => decodeShopifyId(product?.id, "Product")?.toString())
            .join(","),
          [WishlistShareColumns.PRODUCT_NAMES]: wishlistProducts?.map(product => product?.title).join(","),
          [WishlistShareColumns.SHARE_MEDIUM]: "email",
          [WishlistShareColumns.SHARE_MESSAGE]: note,
          [WishlistShareColumns.SHARED_DATE]: new Date().toLocaleDateString("en-AU"),
          [WishlistShareColumns.WISHLIST_NAME]: sharedListName !== DEFAULT_WISHLIST_NAME ? sharedListName : "Default Wishlist",
          [WishlistShareColumns.WISHLIST_ID]: wishlistId,
        },
      })
      return (
        await post("lists/emailList", {
          toemail: recipientEmail,
          fromname: senderName,
          note,
          lid: wishlistId,
        })
      )?.data
    },
    [callFunction, customer?.email, customer?.id, decodeShopifyId, fetchWishlists, post]
  )

  const mergeGuestWithAuthenticatedSession = useCallback(
    async (customer: Customer) => {
      const shopifyId = decodeShopifyId(customer?.id, "Customer")
      if (!shopifyId) {
        return false
      }

      setIsSyncing(true)

      const userId = (
        await callFunction("wishlist-admin", {
          action: "guest-validate-sync",
          formData: {
            regid: await getGuestId(),
            useremail: customer?.email,
            useragenttype: "Allkinds",
          },
        })
      )?.regid

      if (!userId) {
        return false
      }

      setUserId(userId)
      await refreshWishlists()

      setIsSyncing(false)

      setStorage(keys.wishlistSynced, true?.toString())

      return true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [decodeShopifyId, keys.wishlistAuthenticated, post, refreshWishlists, renameWishlist, setStorage, setUserId, wishlists]
  )

  useEffect(() => {
    const initWishlists = async () => {
      setWishlists(await fetchWishlists())

      // Check if the user is trying to access a shared wishlist
      // If so, redirect to the shared wishlist page
      // This is added to support legacy shared wishlist links, as we moved to a route
      const sharedList = new URLSearchParams(location.search).get("sharedlist")
      if (sharedList) {
        if (location.pathname?.endsWith(`${routes.SAVED}/`)) {
          navigate(`${routes.WISHLIST_SHARED}?sharedlist=${sharedList}`, { replace: true })
        }
        setSharedWishlist(await fetchSharedWishlist(sharedList))
      }
      setFetched(true)
    }

    initWishlists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (customer) {
      if (!getStorage(keys.wishlistSynced)) mergeGuestWithAuthenticatedSession(customer)
      setWasLoggedIn(true)
    } else if (!customer && wasLoggedIn) refreshWishlists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      fetched,
      wishlists,
      sharedWishlist,
      count,
      isSyncing,
      tryCreateWishlist,
      isInWishlist,
      addToWishlist,
      moveFromWishlist,
      removeFromWishlist,
      renameWishlist,
      deleteWishlist,
      shareWishlist,
      getShareUrl,
      emailSharedWishlist,
    }),
    [
      fetched,
      wishlists,
      sharedWishlist,
      count,
      isSyncing,
      tryCreateWishlist,
      isInWishlist,
      addToWishlist,
      moveFromWishlist,
      removeFromWishlist,
      renameWishlist,
      deleteWishlist,
      shareWishlist,
      getShareUrl,
      emailSharedWishlist,
    ]
  )

  return <WishlistContext.Provider value={contextValue}>{children}</WishlistContext.Provider>
}

export const useWishlistContext = (): ContextProps => ({ ...React.useContext(WishlistContext) } as ContextProps)
