import React, { memo } from "react"

import { useGlobalContext } from "@app/providers/global"

import { useConfigContext } from "@app/providers/config"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShopify } from "@app/hooks/useShopify"
import Icon from "@app/components/Icon"
import Link from "@app/components/Link"

import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Text, Flex, Image } from "@chakra-ui/react"

import type { CartLine } from "@shopify/hydrogen-react/storefront-api-types"

export type CartItemBundleProps = {
  lineComponents: CartLine[]
}

const CartItemBundle: React.FC<CartItemBundleProps> = ({ lineComponents }) => {
  const { additionalBundleAccordion } = useGlobalContext()

  return lineComponents?.length ? (
    <Box w="100%" marginTop={4}>
      <Accordion allowToggle padding="0">
        <AccordionItem border="none" padding="0">
          {({ isExpanded }) => (
            <>
              <AccordionButton padding="0" _hover={{ backgroundColor: "brand.white" }}>
                <Text variant="text12" marginRight="6px">
                  {additionalBundleAccordion}
                </Text>
                <Icon name="chevronDownDropdown" width="10px" transform={isExpanded ? "rotate(180deg)" : "none"} color="black" />
              </AccordionButton>
              <AccordionPanel p={0} display="flex" flexDir="column" gap={3}>
                {lineComponents.map((lineItem: CartLine) => {
                  return <BundleItem key={lineItem.id} lineItem={lineItem} />
                })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  ) : null
}

type BundleItemProps = {
  lineItem: CartLine
}

const BundleItem: React.FC<BundleItemProps> = ({ lineItem }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { imageNormaliser, formatMoney } = useShopify()

  const title = lineItem.merchandise.product.title
  const variant = lineItem.merchandise
  const image = imageNormaliser(lineItem.merchandise.image, "72")

  const originalPrice = parseFloat(variant.priceV2.amount) * lineItem.quantity

  const bundleItemPrice = parseFloat(lineItem.cost.totalAmount?.amount)

  const isBundleItemDiscounted = originalPrice > bundleItemPrice

  return (
    <Flex
      as={Link}
      to={urlResolver(lineItem.merchandise.product, routes.PRODUCT)?.url}
      justifyContent="space-between"
      alignContent="flex-start"
      _hover={{ textDecoration: "none" }}
      flexDir={{ base: "column", sm: "row" }}
      gap={1}
    >
      <Flex alignItems="center" justifyContent="flex-start">
        {image && <Image src={image?.src} objectFit="cover" boxSize="100%" objectPosition="center" maxWidth="58px" marginRight="15px" />}
        <Box>
          <Text variant="text12" lineHeight="16px">
            {title}
          </Text>
          {variant.title && (
            <Text variant="text12" color="primary.greyDark" lineHeight="16px">
              {variant.title}
            </Text>
          )}
        </Box>
      </Flex>
      <Flex alignItems="flex-end" gap={1.5} flexWrap="wrap" alignSelf="flex-end">
        <>
          {isBundleItemDiscounted && (
            <Text variant="text15" fontWeight={500} textDecoration="line-through" color="brand.grayDisabled">
              {formatMoney(originalPrice)}
            </Text>
          )}
          <Text variant="text15" fontWeight={500} color={isBundleItemDiscounted ? "brand.red" : "brand.darkBlue"}>
            {isBundleItemDiscounted ? formatMoney(bundleItemPrice) : formatMoney(originalPrice)}
          </Text>
        </>
      </Flex>
    </Flex>
  )
}

export default memo(CartItemBundle)
