import { useMemo } from "react"

export const useCartItemComputed = (item: any) => {
  const findAttribute = (key: string) => item?.attributes?.find((attr: any) => attr?.key === key)?.value

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recipientName = useMemo(() => findAttribute("_gift_recipient_name"), [item])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recipientEmail = useMemo(() => findAttribute("_gift_recipient_email"), [item])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recipientDeliveryDate = useMemo(() => findAttribute("_gift_recipient_delivery_date"), [item])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recipientMessage = useMemo(() => findAttribute("_gift_recipient_message"), [item])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const label = useMemo(() => findAttribute("_label"), [item])

  return { recipientName, recipientEmail, recipientDeliveryDate, recipientMessage, label }
}
