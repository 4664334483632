import { useCallback, useEffect, useMemo, useState } from "react"
import config from "@root/config.js"
import { useCore } from "@app/hooks/useCore"
import { useCart } from "@app/hooks/useCart"
import { useConfigContext } from "@app/providers/config"
import { useShopify } from "@app/hooks/useShopify"
import { Product, ProductVariant } from "@shopify/hydrogen-react/storefront-api-types"
import { useStaticQuery, graphql } from "gatsby"

export const useBundle = (product: BundleProduct, quantity = 1) => {
  const {
    helpers: { edgeNormaliser, decodeShopifyId },
  } = useCore()
  const {
    settings: { cart: cartSettings },
  } = useConfigContext()
  const { bundleIdAttribute, bundleParentAttribute, bundleDiscountAttribute } = cartSettings
  const { addToCartMultiple, loading: isLoading } = useCart()
  const { getProductsLight, formatMoney } = useShopify()

  const [products, setProducts] = useState<Product[]>([])
  const [activeVariants, setActiveVariants] = useState<ProductVariant[]>([])

  const { bundles: sanityBundles } = useStaticQuery<GatsbyTypes.StaticBundlesQuery>(graphql`
    query StaticBundles {
      bundles: allSanityBundle {
        edges {
          node {
            id
            bundleProduct {
              title
              image
              shopify {
                id
                handle
                raw
              }
            }
            bundleItems {
              id
              title
              image
              descriptionHtml
              _updatedAt
              shopify {
                handle
              }
            }
            discountAmount
          }
        }
      }
    }
  `)

  const isBundle = useMemo(
    () => !!product?.tags?.find((tag): tag is string => tag === config.settings.product.bundle) ?? false,
    [product?.tags]
  )
  const bundles = edgeNormaliser(sanityBundles)

  const bundle = useMemo(
    () =>
      bundles.length
        ? bundles?.find(({ bundleProduct }: { bundleProduct: GatsbyTypes.SanityProduct }) => {
            return bundleProduct ? bundleProduct?.shopify?.handle === product?.handle : null
          })
        : [],
    [bundles, product]
  )

  const bundleId = useMemo(() => bundle?.id, [bundle])
  const bundleProduct = useMemo(() => bundle?.bundleProduct, [bundle])

  const bundleProductVariantId = useMemo(() => {
    if (!bundleProduct?.shopify?.raw) return false
    const parsedBundleProductData = JSON.parse(bundleProduct.shopify.raw)

    const activeVariantId = parsedBundleProductData?.variants?.[0]?.id

    return activeVariantId ? decodeShopifyId(activeVariantId, "ProductVariant") : false
  }, [bundleProduct?.shopify?.raw, decodeShopifyId])

  const bundleItems = useMemo(() => bundle?.bundleItems?.map((bundle: GatsbyTypes.SanityProduct) => bundle), [bundle])
  const bundleDiscount = useMemo(() => bundle?.discountAmount ?? 0, [bundle])
  const bundleDiscountPercentage = useMemo(() => (bundleDiscount > 0 ? `${bundleDiscount}%` : ""), [bundleDiscount])

  const bundlePriceFull = useMemo(() => {
    const total =
      products?.reduce((sum, { variants }: Product) => {
        const firstVariantPrice = variants?.[0]?.priceV2?.amount ?? 0
        return sum + parseFloat(firstVariantPrice * quantity)
      }, 0) ?? 0

    return formatMoney(total)
  }, [products, formatMoney, quantity])

  const bundlePriceDiscounted = useMemo(() => {
    const total =
      products?.reduce((sum, { variants }: Product) => {
        const firstVariantPrice = variants?.[0]?.priceV2?.amount ?? 0
        return sum + parseFloat(firstVariantPrice)
      }, 0) ?? 0
    const discount = bundleDiscount ? (total * bundleDiscount) / 100 : 0

    const finalPrice = Math.floor((total - discount) * quantity * 100) / 100

    return formatMoney(finalPrice)
  }, [products, bundleDiscount, formatMoney, quantity])

  const whatsInside = useMemo(() => {
    if (!isBundle || !products?.length) return null
    return products?.map(({ id, title, variants, description }: Product) => ({
      id,
      title,
      variant: variants?.[0]?.title ?? null,
      description,
    }))
  }, [isBundle, products])

  const isOnSale = useMemo(() => (bundleDiscount > 0 ? true : false), [bundleDiscount])

  const isInStock = useMemo(
    () => !products?.some(({ variants }: Product) => variants?.some((variant: ProductVariant) => !variant.availableForSale)) ?? true,
    [products]
  )

  useEffect(() => {
    const fetchProducts = async () => {
      const handles = bundleItems?.map(({ shopify }: any) => shopify?.handle)
      // const products = await getProductsLight({ firstImages: 0, firstVariants: 1, handles })
      const products = (await getProductsLight({ firstImages: 2, firstVariants: 1, handles }))?.filter((item: any) => item?.handle)

      setProducts(products.length ? products : [])
    }
    if ((isBundle && !!bundle) || bundleItems?.length) {
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBundle, bundleItems])

  useEffect(() => {
    if (products.length) {
      setActiveVariants(products.map(({ variants }: Product) => variants[0]?.id).filter(Boolean))
    }
  }, [products])

  const addBundleToCart = useCallback(
    async (quantity: number) => {
      if (isInStock && !!activeVariants && !!bundleProductVariantId) {
        await addToCartMultiple(
          activeVariants.map(variant => {
            return {
              variantId: variant,
              quantity: quantity,
              attributes: [
                {
                  key: bundleIdAttribute,
                  value: `${bundleId}`,
                },
                {
                  key: bundleParentAttribute,
                  value: `${bundleProductVariantId}`,
                },
                {
                  key: bundleDiscountAttribute,
                  value: `${bundleDiscount}`,
                },
              ],
            }
          })
        )
      }
    },
    [
      activeVariants,
      addToCartMultiple,
      bundleDiscount,
      bundleDiscountAttribute,
      bundleId,
      bundleIdAttribute,
      bundleParentAttribute,
      bundleProductVariantId,
      isInStock,
    ]
  )

  return {
    addBundleToCart,
    activeVariants,
    bundle,
    bundleDiscount,
    bundleDiscountPercentage,
    bundleId,
    bundlePriceDiscounted,
    bundlePriceFull,
    bundleProduct,
    bundleProductVariantId,
    isLoading,
    isBundle,
    isInStock,
    isOnSale,
    products,
    whatsInside,
  }
}

export type BundleProduct = GatsbyTypes.SanityProduct | Product
