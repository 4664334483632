import { useCore } from "@app/hooks/useCore"

export type AnyUrlResolverType = {
  title: any
  url: any
  external: boolean
} | null

export const useRoutes = () => {
  const {
    helpers: { getUrlParameter, setUrlParameter },
  } = useCore()

  const parentResolver = (parentUrls: Array<string>, item: any) => {
    if (item?.parentCollection?.shopify?.handle) {
      parentUrls.unshift(`/${item?.parentCollection?.shopify?.handle}`)
      parentResolver(parentUrls, item.parentCollection)
    }
  }

  const urlResolver = (source: any, route = "") => {
    const urlParts = []
    const parentUrls: Array<string> = []
    const type = source?._type || source?.document?._type
    const item = source?.[type] || source?.document || source
    const external = source?.external || false
    const url = item?.url || source?.link || ""
    const title = source?.title || item?.title || ""
    const handle = item?.meta?.canonicalUrl || item?.handle?.current || item?.shopify?.handle || item?.handle || ""

    if (url?.length) urlParts.push(url)
    if (route?.length && !url?.length) urlParts.push(route)

    parentResolver(parentUrls, item)

    if (parentUrls.length) {
      urlParts.push(...parentUrls)
    }

    if (handle?.length) urlParts.push(`/${handle}`)

    const completeUrl = urlParts?.[0] !== "/home" ? urlParts.join("") || "/" : "/"

    return {
      title,
      url: completeUrl,
      external,
    }
  }

  const anyUrlResolver = (anyLink: any, allowInternalTitle = true): AnyUrlResolverType => {
    if (!anyLink?.type) {
      return null
    }
    if (!anyLink?.type || !anyLink?.[`${anyLink?.type}Link`]) {
      return null
    }

    const internal = urlResolver(anyLink?.[`${anyLink?.type}Link`])

    return {
      title: anyLink?.title || (allowInternalTitle ? internal?.title : null),
      url: anyLink?.type === "external" ? anyLink?.externalLink : internal?.url,
      external: !!anyLink?.external,
    }
  }

  return {
    getUrlParameter,
    setUrlParameter,
    urlResolver,
    anyUrlResolver,
  }
}
