import { useCallback, useEffect, useMemo, useState } from "react"
import { useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Collection, Product, ProductVariant, SelectedOption, Cart } from "@shopify/hydrogen-react/storefront-api-types"

import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useConfigContext } from "@app/providers/config"
import { useCartContext } from "@app/providers/cart"
import { useCustomerContext } from "@app/providers/customer"
import { useProductWorld } from "@app/hooks/useProductWorld"
import { useProductComputed } from "@app/hooks/useProductComputed"

import { GET_COLLECTION_PRODUCT_COMPLETE } from "@app/graphql/queries/collection"
import { GET_MINIMAL_PRODUCT, GET_PRODUCTS_BY_HANDLE_LIGHT } from "@app/graphql/queries/minimalProduct"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShop()
  const { customer } = useCustomerContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const { countryCode, currencyCode } = useCartContext()
  const {
    graphql: {
      queries: { GET_COLLECTIONS_BY_HANDLE },
    },
    helpers: { edgeNormaliser, encodeShopifyId },
  } = useCore()

  const formatErrors = (errors: any) => {
    const formatted = errors?.message
      ? { message: errors?.message }
      : //@ts-ignore
        Object.assign(...errors.map((value: any) => ({ [value?.field?.[1]]: { code: value?.code, message: value?.message } })))
    return formatted
  }

  const formatMoney = (amount: number, currency = "AUD") =>
    new Intl.NumberFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      style: "currency",
      currency: currency,
    })
      .format(amount)
      ?.replace(/^(USD\s)(.+)$/, "$1$$$2")

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      timeZone: "Australia/Melbourne",
    }).format(new Date(date))

  const imageUrl = (src: string, size: string | number): any => {
    const dimensions = `${size}x${size}`
    const match = typeof src === "string" ? src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i) : false
    return match && src?.includes(`shopify.com`) && size && size !== "master"
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, "")
      : src
  }

  const imageSrcSets = (src: string, size: string | number) => {
    if (typeof src !== "string") return
    return (
      src?.includes(`shopify.com`) &&
      [1, 500, 1000, 1500, 2000]
        .filter(set => !size || (size && size >= set))
        .map(set => `${imageUrl(src, set)} ${set}w`)
        .join(`,`)
    )
  }

  const onSale = (price: string, compareAtPrice: string) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = (item: any) => item?.handle || item?.shopify?.handle

  const addressNormaliser = (address: any) => ({
    ...address,
    default: address?.id === customer?.defaultAddress?.id,
  })

  const orderNormaliser = (orders: any) =>
    edgeNormaliser(orders)?.map((order: any) => ({ ...order, lineItems: edgeNormaliser(order?.lineItems) }))

  const imageNormaliser = (image: any, size: string | number = "") => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
  })

  const videoNormaliser = (video: any, size: string | number = "") => ({
    alt: video?.alt || "",
    preview: imageNormaliser(video?.previewImage || "", size),
    src: video?.sources
      ?.filter(({ format }: { format: string }) => format === "mp4")
      ?.sort((a: any, b: any) => (a.height < b.height ? 1 : -1))?.[0],
  })

  const modelNormaliser = (model: any, size: string | number = "") => ({
    alt: model?.alt || "",
    preview: imageNormaliser(model?.previewImage || "", size),
    sources: model?.sources,
  })

  const priceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        .filter((item: any) => (field && item?.[field] ? item[field] : item)?.currencyCode === (currencyCode || shop?.currencyCode))
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const priceFieldNormaliser = (item: any, field = "") => {
    const price = field && item?.[field] ? item[field] : item
    return {
      amount: `${price?.amount}`,
      local: formatMoney(price?.amount),
      afterpay: formatMoney(price?.amount / 4),
      currencyCode: `${price?.currencyCode || price?.currency_code}`,
    }
  }

  const productNormaliser = (product: any) => ({
    ...product,
    collections:
      edgeNormaliser(product?.collections)?.map((collection: any) => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    images:
      edgeNormaliser(product?.images)?.length > 0
        ? edgeNormaliser(product?.images)?.map((image: any) => imageNormaliser(image))
        : edgeNormaliser(product?.media)
            ?.filter((media: any) => media?.mediaContentType === "IMAGE")
            ?.map((media: any) => imageNormaliser(media?.image)),
    media: edgeNormaliser(product?.media)?.map((media: any) =>
      media?.mediaContentType === "VIDEO"
        ? videoNormaliser(media)
        : media?.mediaContentType === "IMAGE"
        ? imageNormaliser(media?.image)
        : null
    ),
    metafields: edgeNormaliser(product?.metafields),
    models: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "MODEL_3D")
      ?.map((media: any) => modelNormaliser(media)),
    variants: variantsNormaliser(product?.variants) || [],
    videos: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "VIDEO" || media?.mediaContentType === "EXTERNAL_VIDEO")
      ?.map((media: any) => videoNormaliser(media)),
  })

  const variantsNormaliser = (variants: any) => edgeNormaliser(variants)?.map(variantNormaliser)

  const variantNormaliser = (variant: any) => ({
    ...variant,
    ...(variant?.image && { image: imageNormaliser(variant?.image) }),
    ...(variant?.metafields && { metafields: edgeNormaliser(variant?.metafields) }),
  })

  const staticVariantNormaliser = (variant: any) => ({
    ...variant,
    price: {
      amount: variant?.priceV2,
      currencyCode: shop?.currencyCode,
    },
    compareAtPrice: {
      amount: variant?.compareAtPriceV2,
      currencyCode: shop?.currencyCode,
    },
    priceV2: {
      amount: variant?.priceV2,
      currencyCode: shop?.currencyCode,
    },
    compareAtPriceV2: {
      amount: variant?.compareAtPriceV2,
      currencyCode: shop?.currencyCode,
    },
  })

  const adminProductNormaliser = (product: any, config?: any) => ({
    ...product,
    url: routes.PRODUCT,
    availableForSale: product?.variants?.filter(({ available }: { available: boolean }) => available)?.length > 0,
    id: encodeShopifyId(product?.id, "Product"),
    images: product?.images?.map((image: any) => imageNormaliser(image, config?.imageSize || false)) || [],
    legacyId: product?.id,
    productType: product?.product_type,
    priceRange: {
      minVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.min_variant_price, "price"),
      maxVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.max_variant_price, "price"),
    },
    variants: product?.variants?.map((variant: any) => ({
      ...variant,
      availableForSale: variant?.available,
      id: encodeShopifyId(variant?.id, "ProductVariant"),
      legacyId: variant?.id,
      priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
      compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
    })),
  })

  const adminPriceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...presentmentPrices
        .filter((item: any) => (field && item?.[field] ? item[field] : item)?.currency_code === (currencyCode || shop?.currencyCode))
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const adminPresentmentPriceNormaliser = (presentment_prices: any) =>
    presentment_prices?.map((presentmentPrice: any) => ({
      compareAtPrice: {
        amount: presentmentPrice?.compare_at_price?.amount,
        currencyCode: presentmentPrice?.compare_at_price?.currency_code,
      },
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const collectionNormaliser = (collection: any) => ({
    ...collection,
    id: parseInt(collection?.shopify?.id) || collection?.id,
    handle: collection?.shopify?.handle || collection?.handle,
    image: imageNormaliser(collection?.image),
    ...(collection?.metafields && { metafields: edgeNormaliser(collection?.metafields) }),
    products: collection?.products?.edges?.length ? edgeNormaliser(collection?.products).map(productNormaliser) : [],
  })

  const cartNormaliser = (cart: Cart) => ({
    ...cart,
    lines: edgeNormaliser(cart?.lines) || [],
    deliveryGroups: edgeNormaliser(cart?.deliveryGroups),
  })

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstProducts = 0,
    firstVariants = 0,
    handle = "",
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        countryCode,
        handle,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstProducts,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getCollections = async ({
    countryCode = "AU",
    handles = [],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTIONS_BY_HANDLE(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    //@ts-ignore
    return handles?.map(handle => collectionNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getProductsDict = async ({
    countryCode = "AU",
    handles = [],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
  }) => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE_LIGHT(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    return data
  }

  const getProducts = async ({
    countryCode = "AU",
    handles = [],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
  }) => {
    const data = await getProductsDict({ countryCode, handles, firstCollections, firstImages, firstMedia, firstMetafields, firstVariants })
    //@ts-ignore
    return handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getProductsLight = async ({ firstImages = 0, firstVariants = 0, handles = [] }) => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE_LIGHT(handles),
      variables: {
        countryCode,
        firstImages,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    //@ts-ignore
    return handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    formatErrors,
    onSale,
    imageUrl,
    imageSrcSets,
    formatDate,
    formatMoney,
    imageNormaliser,
    cartNormaliser,
    orderNormaliser,
    addressNormaliser,
    productNormaliser,
    variantNormaliser,
    variantsNormaliser,
    staticVariantNormaliser,
    collectionNormaliser,
    adminProductNormaliser,
    getHandle,
    getCollection,
    getCollections,
    getProductsDict,
    getProducts,
    getProductsLight,
  }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useAppContext()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products") && product) {
        let currentProduct = product

        try {
          currentProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
        } catch (e) {
          console.error((e as Error).message)
        }

        if (!activeProduct || activeProduct.id !== currentProduct.id) {
          setActiveProduct(currentProduct)
        }
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct]
  )

  return { activeProduct, selectProduct }
}

export const useShopifyFirstAvailable = (product: ProductProps) => {
  const variant = product?.variants?.find(({ availableForSale }) => availableForSale) || product?.variants?.[0]

  return { variant }
}

export const useShopifyProductRaw = (product: any) => {
  const { world } = useProductWorld(product)
  const { staticVariantNormaliser } = useShopify()
  try {
    const rawProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
    return {
      product: {
        ...rawProduct,
        variants: rawProduct.variants.map(staticVariantNormaliser),
        siblings: product?.siblings,
        related: product?.related,
        fragrences: product?.fragrences,
        giftSet: product?.giftSet,
        howToUse: product?.howToUse,
        productHighlights: product?.productHighlights,
        world,
        inStoreOnly: product?.tags?.includes("in-store-only"),
        published: true,
      },
    }
  } catch (err) {
    console.error("Error parsing raw shopify product")
    return { product }
  }
}

export const useIsProductUnavailable = (product: any, inView: boolean) => {
  const {
    store: { locationRegion },
  } = useConfigContext()
  const { countryCode } = useCartContext()
  const [getProduct, { data, called, loading, error }] = useLazyQuery(GET_MINIMAL_PRODUCT, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode: countryCode || locationRegion,
      handle: product?.handle || "",
    },
  })

  if (error) console.error(error)

  useEffect(() => {
    if (product?.handle && countryCode && !called && inView) {
      getProduct()
    }
  }, [product?.handle, called, countryCode, getProduct, inView])

  const isUnavailable = data?.product === null

  return { loading, isUnavailable }
}

export const useShopifyProductLive = (product: any) => {
  const {
    graphql: {
      queries: { GET_PRODUCT_LITE },
    },
  } = useCore()
  const {
    store: { locationRegion },
  } = useConfigContext()
  const { countryCode } = useCartContext()
  const { variantsNormaliser } = useShopify()
  const [getProduct, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT_LITE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      countryCode: countryCode || locationRegion,
      handle: product.handle,
      firstImages: 0,
      firstMedia: 0,
      firstVariants: 100,
    },
  })

  if (error) console.error(error)

  useEffect(() => {
    if (product.handle && countryCode && !called) getProduct()
  }, [product.handle, called, countryCode, getProduct])

  const isUnavailable = data?.product === null

  let liveProduct = undefined
  try {
    liveProduct = data && {
      ...product,
      variants: product.variants.map((variant: any, index: number) => ({
        ...variant,
        ...variantsNormaliser(data.product.variants)[index],
      })),
    }
    return { product: liveProduct, loading, error, isUnavailable }
  } catch (err) {
    console.error("Error parsing live shopify product....")
    return { product, loading, error, isUnavailable }
  }
}

type ProductProps = Product & {
  collections?: Array<Collection>
  variants: Array<ProductVariant>
}

type UseShopifyVariantsProps = {
  firstAvailable?: boolean
  loading?: boolean
  product: ProductProps
  selectedVariant?: ProductVariant
  useParameter?: boolean
}

type UseShopifyVariants = {
  activeVariant: ProductVariant | null
  handleOption: (option: SelectedOption) => void
  handleOptions: (options: Array<SelectedOption>) => void
  selectedOptions: Array<SelectedOption>
  variant: ProductVariant | null
}

export const useShopifyVariants = ({
  firstAvailable = true,
  loading = false,
  product,
  selectedVariant,
  useParameter = false,
}: UseShopifyVariantsProps): UseShopifyVariants => {
  const {
    helpers: { encodeShopifyId, decodeShopifyId, getUrlParameter, isBrowser, setUrlParameter },
  } = useCore()
  const { activeVariant, activeProduct, setActiveVariant } = useAppContext()
  const { currencyCode } = useCartContext()
  const { shop } = useShop()
  const {
    settings: { params },
  } = useConfigContext()
  const { trackProductView } = useAnalytics()
  const { isGiftCard } = useProductComputed(product)
  const { id, variants } = product || {}
  const [variant, setVariant] = useState<ProductVariant | null>(null)
  const currentVariant = getUrlParameter(params?.variant)
  const { variant: firstAvailableVariant } = useShopifyFirstAvailable(product)

  const normaliseVariantId = (id: string) => {
    if (isGiftCard) return encodeShopifyId(id.replace(/\D/g, ""), "ProductVariant")
    return id
  }

  const defaultVariant =
    selectedVariant ||
    (useParameter &&
      variants?.find(({ id }: { id: string }) => {
        const normalisedId = normaliseVariantId(id)
        return normalisedId === encodeShopifyId(currentVariant, "ProductVariant")
      })) ||
    (firstAvailable && firstAvailableVariant)

  const defaultOptions = defaultVariant?.selectedOptions || product?.options?.map(({ name }) => ({ name, value: "" }))
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions)

  const handleOption = useCallback(
    option => setSelectedOptions(selectedOptions?.map(selectedOption => (selectedOption.name === option.name ? option : selectedOption))),
    [selectedOptions, setSelectedOptions]
  )

  const handleOptions = useCallback(options => setSelectedOptions(options), [setSelectedOptions])

  useEffect(() => {
    if (
      (currencyCode || shop?.currencyCode) &&
      !loading &&
      activeVariant &&
      !isGiftCard &&
      (!activeVariant?.priceV2?.amount || activeVariant?.priceV2?.amount != defaultVariant?.priceV2?.amount) &&
      defaultVariant?.priceV2?.amount
    ) {
      setSelectedOptions(defaultVariant?.selectedOptions)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, id, loading, shop?.currencyCode, variants?.length, defaultVariant?.selectedOptions, defaultVariant, isGiftCard])

  // intentionally exclude variants from dependencies
  useEffect(() => {
    if (useParameter) {
      const currentVariant =
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null

      if (!loading) setActiveVariant(currentVariant)
    } else {
      setVariant(
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selectedOptions])

  useEffect(() => {
    if (useParameter && activeVariant?.id && !loading && isBrowser) {
      if (
        currentVariant !== encodeShopifyId(activeVariant.id, "ProductVariant") &&
        // Only check that the active variant is not the default, if there isn't a variant currently selected in the query string
        (activeVariant.id !== defaultVariant.id || !!currentVariant) &&
        product?.variants?.find(({ id }) => id === activeVariant?.id)
      ) {
        window.history.replaceState(
          null,
          window.document.title,
          setUrlParameter(params.variant, decodeShopifyId(activeVariant.id, "ProductVariant"))
        )
      }

      if (currencyCode && activeVariant?.id && activeVariant?.priceV2?.amount && !loading) {
        trackProductView(activeProduct, activeVariant, false)
      }
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant?.id, activeVariant?.priceV2?.amount, currencyCode, loading])

  return { activeVariant, handleOption, handleOptions, selectedOptions, variant }
}

export const useShopifyPrice = (variant: any, quantity = 1) => {
  const { currencyCode } = useCartContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant || !currencyCode) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        formattedCompareAtPrice: undefined,
      }
    }

    const price = variant?.priceV2?.amount ? Number(variant?.priceV2?.amount) : 0
    const compareAtPrice = variant?.compareAtPriceV2?.amount ? Number(variant?.compareAtPriceV2?.amount) : 0
    const onSale = !!compareAtPrice && !!price && compareAtPrice > price
    const formattedPrice = formatMoney(price * quantity, currencyCode)
    const formattedCompareAtPrice = formatMoney(compareAtPrice * quantity, currencyCode)

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      formattedPrice,
      formattedCompareAtPrice,
    }
  }, [quantity, variant, currencyCode, formatMoney])
}

export const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { currencyCode } = useCartContext()
  const { formatMoney } = useShopify()
  const {
    helpers: { formatPrice },
  } = useCore()

  return useMemo(() => {
    if (!product || !currencyCode) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceMin: undefined,
        formattedPriceRange: undefined,
      }
    }

    const priceRangeMin = product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceMin = formatMoney(priceMin, currencyCode)
    const formattedPriceRange =
      priceMin < priceMax && priceMin > 0
        ? `${formattedPriceMin}${separator}${formatPrice(`${priceMax}`)}`
        : formatMoney(priceMax, currencyCode)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceMin,
      formattedPriceRange,
    }
  }, [product, currencyCode, separator, formatMoney, formatPrice])
}
