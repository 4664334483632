import { useTheme, useMediaQuery } from "@chakra-ui/react"

import { useHydration } from "./useHydration"

type UseMedia = {
  isBase: boolean
  isXSmall: boolean
  isSmall: boolean
  isMedium: boolean
  isLarge: boolean
  isXLarge: boolean
  isTouch: boolean
  isHydrated: boolean
}

export const useMedia = (): UseMedia => {
  const { breakpoints } = useTheme()
  const { isHydrated } = useHydration()

  const queries = {
    base: useMediaQuery(`(max-width: ${breakpoints.sm.replace("px", "") - 1}px)`)?.[0],
    xs: useMediaQuery(`(min-width: ${breakpoints.xs})`)?.[0],
    sm: useMediaQuery(`(min-width: ${breakpoints.sm})`)?.[0],
    md: useMediaQuery(`(min-width: ${breakpoints.md})`)?.[0],
    lg: useMediaQuery(`(min-width: ${breakpoints.lg})`)?.[0],
    xl: useMediaQuery(`(min-width: ${breakpoints.xl})`)?.[0],
    touch: useMediaQuery(`(hover: none)`)?.[0],
  }

  const isBase = isHydrated ? queries.base : true
  const isXSmall = isHydrated ? queries.xs : false
  const isSmall = isHydrated ? queries.sm : false
  const isMedium = isHydrated ? queries.md : false
  const isLarge = isHydrated ? queries.lg : false
  const isXLarge = isHydrated ? queries.xl : false
  const isTouch = isHydrated ? queries.touch : false

  return { isBase, isXSmall, isSmall, isMedium, isLarge, isXLarge, isTouch, isHydrated }
}
