import React from "react"
import { Button, Flex } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"

type Props = {
  isDisabled: boolean
}

export const CartCheckout: React.FC<Props> = React.memo(({ isDisabled }) => {
  const { count, gotoCheckout, loading } = useCartContext()

  return count > 0 ? (
    <Flex m="16px 20px">
      <Button
        fontWeight={500}
        lineHeight="15px"
        _disabled={{ bgColor: "#A4A5AA" }}
        variant="fillSmall"
        w="100%"
        height="unset"
        px="30px"
        py="26px"
        onClick={gotoCheckout}
        isLoading={loading}
        isDisabled={loading || isDisabled}
      >
        Checkout
      </Button>
    </Flex>
  ) : null
})
