import React from "react"
import { Box, Text } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"

export const CartThreshold: React.FC = React.memo(() => {
  const { freeShipping } = useCart()
  const { gwpSettings, message } = useGiftWithPurchase()

  if (gwpSettings?.giftWithPurchaseEnabled && message) {
    return (
      <Box textAlign="center">
        <Text variant="text12" pt={2}>
          {message}
        </Text>
      </Box>
    )
  }

  return freeShipping?.message ? (
    <Box textAlign="center">
      <Text variant="text12" pt={2}>
        {freeShipping.message}
      </Text>
    </Box>
  ) : null
})
