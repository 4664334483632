import React from "react"
import { Box, Progress } from "@chakra-ui/react"

import { useCart } from "@app/hooks/useCart"
import { useGiftWithPurchase } from "@app/hooks/useGiftWithPurchase"

interface CartProgressProps {
  cartMiniProgress?: number
}

export const CartProgress: React.FC<CartProgressProps> = React.memo(({ cartMiniProgress }) => {
  const { freeShipping } = useCart()
  const { gwpSettings, percentage } = useGiftWithPurchase()
  const progress = gwpSettings?.giftWithPurchaseEnabled ? percentage : freeShipping.percentage

  if (!(freeShipping.threshold >= 0) && !gwpSettings?.giftWithPurchaseEnabled) return null

  return (
    <Box>
      <Progress
        opacity={0.5}
        value={cartMiniProgress || progress}
        h="6px"
        sx={{
          "& > div": {
            background: "linear-gradient(90deg,#ffae61,#eaac3e,#a5e065,#2bd2c9,#77a5ff,#f9423a)",
          },
        }}
      />
    </Box>
  )
})
