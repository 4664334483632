const paragraphModifier = {
  fontFamily: "Graphik",
  my: 3,
}

const text12Modifier = {
  fontSize: 12,
  lineHeight: "14px",
}

const text14Modifier = {
  fontSize: 14,
  lineHeight: "20px",
}

const text16Modifier = {
  fontSize: 16,
  lineHeight: "15px",
}

const text18Modifier = {
  fontSize: 18,
  lineHeight: "26px",
}

const text20Modifier = {
  fontSize: 20,
  lineHeight: "28px",
}

export const Text = {
  baseStyle: {
    color: "inherit",
    fontFamily: "Larsseit",
    fontFeatureSettings: '"ss01" on, "ss02" on',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
  },
  variants: {
    paragraph: {
      ...paragraphModifier,
      fontSize: [18, null, 20],
      lineHeight: [1.4, null, 1.5],
    },
    paragraph12: {
      ...paragraphModifier,
      ...text12Modifier,
    },
    paragraph14: {
      ...paragraphModifier,
      ...text14Modifier,
    },
    paragraph16: {
      ...paragraphModifier,
      ...text16Modifier,
    },
    paragraph18: {
      ...paragraphModifier,
      ...text18Modifier,
    },
    paragraph20: {
      ...paragraphModifier,
      ...text20Modifier,
    },
    paragraph24: {
      ...paragraphModifier,
      fontSize: 24,
      lineHeight: 1.4,
    },

    text12: text12Modifier,
    text14: text14Modifier,
    text15: {
      fontSize: 15,
      lineHeight: "23px",
    },
    text16: text16Modifier,
    text18: text18Modifier,
    text20: text20Modifier,
    text22: {
      fontSize: 22,
      lineHeight: "28px",
    },
    text24: {
      fontSize: 24,
      lineHeight: 1.4,
    },
    mono: {
      fontFamily: "Courier",
    },
    footerItem: {
      fontSize: 16,
      lineHeight: ["23.2px", null, "30px"],
      transition: "all .5s ease-in-out",
      whiteSpace: "nowrap",
      outline: "none !important",
    },
    footerSubscribeMessage: {
      fontSize: 20,
      lineHeight: "30px",
    },
    footerCopyright: {
      fontSize: 10,
      lineHeight: ["18.5px", null, "20.7px"],
      textTransform: "uppercase",
      opacity: 0.4,
    },
  },
}
