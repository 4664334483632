import { useEffect } from "react"

import { useCustomerContext } from "@app/providers/customer"
import { useLocationContext } from "@app/providers/location"
import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"

interface WebsiteData {
  [key: string]: {
    country: string
    currency: string
    language: string
  }
}
export const websiteData: WebsiteData = {
  allkindsus: { country: "US", currency: "USD", language: "en" },
  allkindsau: { country: "AU", currency: "AUD", language: "en" },
}

export const updateInsideData = (key: string, data: any) => {
  if (!window._insideData) {
    window._insideData = {}
  }

  if (!window._insideData[key]) {
    window._insideData[key] = {}
  }

  window._insideData[key] = {
    ...window._insideData[key],
    ...data,
  }
}

export const useInsideDataWebsite = () => {
  const {
    store: { id },
  } = useLocationContext()
  useEffect(() => {
    const data = websiteData[id] || {}
    updateInsideData("website", data)
  }, [id])
}

export const useInsideDataProduct = (product: any, activeVariant: any) => {
  const { productType, title } = product
  let sku: string, price: string, image: string

  if (activeVariant) {
    price = activeVariant.priceV2?.amount ?? ""
    sku = activeVariant.sku
    image = activeVariant.image?.originalSrc || activeVariant.image?.src || ""
  } else {
    price = product.variants[0].priceV2?.amount ?? ""
    sku = product.variants[0].sku ?? ""
    image = product.image || product.images[0].originalSrc || ""
  }
  useEffect(() => {
    const data = { image, price, sku, category: productType, name: title }
    updateInsideData("product", data)
  }, [productType, image, title, price, sku])
}

export const useInsideDataUser = () => {
  const data = useCustomerContext()
  const customer = data?.customer
  useEffect(() => {
    if (!customer) return
    const { email, id, firstName, lastName } = customer
    const data = { email, id, name: `${firstName} ${lastName}` }
    updateInsideData("user", data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(customer)])
}

export const useInsideDataPage = (name: string, type: string) => {
  useInsideDataWebsite()
  useInsideDataCart()
  useEffect(() => {
    const pageName = name == "Default" ? "Homepage" : name
    const pageType = pageName != "Homepage" && type == "homepage" ? "other" : type
    const data = { name: pageName, type: pageType }
    updateInsideData("page", data)
  }, [name, type])
}

export const useInsideDataCart = () => {
  const { store } = useConfigContext()
  const { cart } = useCartContext()
  const lineItems = cart?.lines || []
  const subtotal = cart?.cost?.subtotalAmount?.amount ?? 0
  const items = lineItems
    .filter(item => item?.merchandise.product)
    .map(item => {
      const { merchandise, quantity } = item
      const { image, product, sku, priceV2 } = merchandise
      const src = image?.originalSrc || image?.url || ""
      const { productType, handle } = product
      const { amount } = priceV2
      const url = `https://${store.url}/products/${handle}`
      return { name: product.title, price: amount, quantity, sku, url, category: productType, image: src }
    })

  useEffect(() => {
    updateInsideData("cart", { items, total: subtotal })
  }, [items, subtotal])
}
