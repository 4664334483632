import { DotHeadless } from "@dotdev/dotheadless-app"
import { useLocationContext } from "@app/providers/location"
import config from "@root/config.js"

export const useCore = () => ({ ...DotHeadless })

export const useStorage = () => {
  const { store } = useLocationContext() as StoreLocation
  const storeId = store?.id || config.store.id

  const getStorage = (key: string) => DotHeadless.helpers.storage.get(`${storeId}:${key}`)

  const setStorage = (key: string, value: any, expiryDays?: number) =>
    DotHeadless.helpers.storage.set(`${storeId}:${key}`, value, expiryDays || undefined)

  const removeStorage = (key: string) => DotHeadless.helpers.storage.remove(`${storeId}:${key}`)

  return { getStorage, setStorage, removeStorage }
}
