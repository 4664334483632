import React, { useCallback } from "react"
import { BoxProps, IconButton } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCustomerContext } from "@app/providers/customer"
import { useConfigContext } from "@app/providers/config"
import { useMedia } from "@app/hooks/useMedia"
import { useCore } from "@app/hooks/useCore"
import Link from "@app/components/Link"
import Icon from "@app/components/Icon"

const AccountWidget: React.FC<BoxProps> = ({ ...props }) => {
  const { dispatch } = useAppContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { customer } = useCustomerContext()
  const { isBase } = useMedia()

  const onClick = useCallback(() => {
    dispatch({
      type: "menu",
      payload: false,
    })
    dispatch({
      type: "header",
      payload: null,
    })
  }, [dispatch])

  return isBrowser && !isBase ? (
    <Link to={customer ? routes.DASHBOARD : routes.LOGIN} display={["none", "block"]} onClick={onClick} tabIndex={-1}>
      <IconButton
        variant="headerIcon"
        aria-label="Manage account"
        icon={<Icon name="account" width={12} height={12} p={3.5} />}
        {...props}
      />
    </Link>
  ) : null
}

export default React.memo(AccountWidget)
