import gql from "graphql-tag"

import { MONEY_FRAGMENT } from "@app/graphql/fragments/moneyFragment"

export const DELIVERY_OPTION_FRAGMENT = gql`
  fragment DeliveryOptionFragment on CartDeliveryOption {
    code
    deliveryMethodType
    description
    estimatedCost {
      ...MoneyFragment
    }
    handle
    title
  }
  ${MONEY_FRAGMENT}
`
