import React, { useState } from "react"
import { AspectRatio, Box, Button, Flex, Text } from "@chakra-ui/react"

import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"
import ResolvedImage from "@app/components/ResolvedImage"

type Props = {
  item: GatsbyTypes.SanityObjectNavContent | null
  handleLinkClick?: () => void
  itemColour: any
  fullWidth?: boolean
}

const NavigationHeaderMegaLevel2Content: React.FC<Props> = ({ item, handleLinkClick, itemColour, fullWidth = false }) => {
  const { anyUrlResolver } = useRoutes()
  const resolvedCardLink = anyUrlResolver(item?.objectNavCard?.link)
  const [isSelected, setIsSelected] = useState(false)
  const [handleActiveLink, setHandleActiveLink] = useState<GatsbyTypes.SanityLink | null>(null)

  const handleSelection = () => {
    setIsSelected(prev => !prev)
  }

  return (
    <Box
      width="100%"
      position="relative"
      onMouseEnter={handleSelection}
      onFocus={handleSelection}
      onBlur={handleSelection}
      onMouseLeave={handleSelection}
    >
      {item?.contentType === "card" && (
        <>
          <AspectRatio ratio={fullWidth ? 936 / 324 : 460 / 324}>
            <>
              <Box as={Link} to={resolvedCardLink?.url} onClick={handleLinkClick} borderRadius="8px">
                <ResolvedImage image={item?.objectNavCard?.image} borderRadius="8px" w="100%" />
              </Box>
            </>
          </AspectRatio>
          {item?.objectNavCard?.cardText && (
            <Flex
              pointerEvents="none"
              alignItems="center"
              justifyContent="space-between"
              px={4.5}
              cursor="pointer"
              position="absolute"
              bottom={0}
              bgColor={itemColour?.preset || "preset.default"}
              height={10}
              w="100%"
              borderBottomRadius={"8px"}
              color="primary.white"
            >
              <Text color="primary.white" textDecoration={isSelected ? "underline" : "none"}>
                {item?.objectNavCard?.cardText}
              </Text>
            </Flex>
          )}
        </>
      )}
      {item?.contentType === "links" && (
        <Flex gap={10}>
          {item?.objectNavSubLinks?.subLinks?.map(subLink => (
            <Flex key={subLink._key} flexDirection="column" gap={2.5}>
              <Text fontSize="xs">{subLink?.title}</Text>
              {subLink?.links.map(link => (
                <SubLink
                  key={link?._key}
                  link={link}
                  size={item.objectNavSubLinksSize || "small"}
                  activeLink={handleActiveLink}
                  handleActiveLink={setHandleActiveLink}
                  handleLinkClick={handleLinkClick}
                />
              ))}
            </Flex>
          ))}
        </Flex>
      )}
    </Box>
  )
}

const SubLink: React.FC<{
  link: GatsbyTypes.SanityLink
  size: string
  activeLink: GatsbyTypes.SanityLink
  handleActiveLink: (state: GatsbyTypes.SanityLink | null) => void
  handleLinkClick?: () => void
}> = ({ link, size, activeLink, handleActiveLink, handleLinkClick }) => {
  const { anyUrlResolver } = useRoutes()

  const resolvedNavLink = anyUrlResolver(link)

  const isActiveLink = activeLink?._key === link?._key

  return size === "large" ? (
    <Box>
      <Button
        variant="headerMegaItemLevel2"
        as={Link}
        to={resolvedNavLink?.url}
        justifyContent="flex-start"
        onClick={() => {
          handleLinkClick?.()
        }}
        onMouseEnter={() => handleActiveLink(link)}
        onMouseLeave={() => handleActiveLink(null)}
        opacity={isActiveLink || !activeLink ? 1 : 0.6}
      >
        {link?.title}
      </Button>
    </Box>
  ) : (
    <Link to={resolvedNavLink?.url} onClick={handleLinkClick}>
      {resolvedNavLink?.title}
    </Link>
  )
}

export default React.memo(NavigationHeaderMegaLevel2Content)
