import React from "react"
import { Text, VStack, StackProps } from "@chakra-ui/react"

import { useDiscounts } from "@app/hooks/useDiscounts"
import { useSanityColour } from "@app/hooks/useSanity"

type Props = StackProps & {
  lineHeight?: string
  tags: string[]
  variant?: string
}

const Discount: React.FC<Props> = ({ tags, variant, lineHeight, ...rest }) => {
  const { getDiscountsFromTags } = useDiscounts()
  const discounts = getDiscountsFromTags(tags)

  return discounts.length ? (
    <VStack alignItems="flex-start" {...rest} mb="10px">
      {discounts?.map(
        (discount: GatsbyTypes.SanitySettingDiscounts, idx: number) =>
          discount.enabled && <DiscountText key={`${discount.id}-${idx}`} discount={discount} variant={variant} lineHeight={lineHeight} />
      )}
    </VStack>
  ) : null
}

const DiscountText: React.FC<{ discount: GatsbyTypes.SanitySettingDiscounts; variant?: string; lineHeight?: string }> = ({
  discount,
  variant,
  lineHeight,
}) => {
  const { colour } = useSanityColour({ themeColourText: discount?.themeColourText }, { colour: discount?.themeColourText?.custom?.hex })

  return (
    <Text variant={variant} color={colour} lineHeight={lineHeight} fontSize={{ base: "14px", sm: "14px" }}>
      {discount.title}
    </Text>
  )
}

export default React.memo(Discount)
