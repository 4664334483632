import { Input } from "."

export const Select = {
  baseStyle: {
    field: {
      ...Input.baseStyle.field,
    },
  },
  sizes: {
    default: {},
  },
  variants: {
    address: {
      field: {
        ...Input.variants.address.field,
        _focus: {
          borderColor: "brand.blue",
        },
      },
    },
    reviewsSort: {
      field: {
        fontSize: 16,
        lineHeight: "18px",
        bgColor: "primary.white",
        pl: 4,
        py: 4,
      },
    },
  },
  defaultProps: {
    size: "default",
    variant: "outline",
  },
}
